import { EventSourceType, EventType } from '@/modules/events/api/events/event.contracts';

export interface MovementReadDTO {
  readonly id: number;
  readonly occurredAt: Date;
  readonly type: MovementType;
  readonly asset: MovementAsset;
  readonly eventLocation: MovementLocation;
  readonly fromLocation?: MovementLocation;
  readonly toLocation?: MovementLocation;
  readonly event: MovementEvent;
}

export enum MovementType {
  INCOMING = 'incoming',
  OUTGOING = 'outgoing',
}

export interface MovementAssetType {
  id: number;
  code: string;
}

export interface MovementAsset {
  id: number;
  code: string;
  assetType: MovementAssetType;
}

export interface MovementLocation {
  id: number;
  name: string;
  code: string;
}

export interface MovementEvent {
  id: number;
  type: EventType;
  source: EventSourceType;
}

export class MovementFilterOptionsDTO {
  assetCode?: string;
  assetId?: number;
  assetTypeId?: number;
  type?: MovementType;
  locationId?: number;
  dateFrom?: string; // ISO date string
  dateTo?: string; // ISO date string
  toLocationId?: number;
  fromLocationId?: number;
}

export enum MovementSortOption {
  OCCURRED_AT = 'occurred-at',
  ASSET_CODE = 'asset-code',
  EVENT_LOCATION = 'event-location',
  EVENT_TYPE = 'event-type',
  EVENT_SOURCE = 'event-source',
}

export interface TimelineLocationDTO {
  id: number;
  name: string;
}

export interface TimelineEventDTO {
  id: number;
  type: EventType;
  occurredAt: Date;
}

export interface TimelineDTO {
  location: TimelineLocationDTO;
  enterEvent: TimelineEventDTO;
  leaveEvent?: TimelineEventDTO;
}
