import { ResourceLanguage } from 'i18next';
import { DisplayMode } from '@/types/display-mode.enum';
import { TopAssetTypesMetric } from '@/modules/dashboards/components/widgets/top-assets-widget/TopAssetTypesWidgetConfiguration';
import { TopLocationsMetric } from '@/modules/dashboards/components/widgets/top-locations-widget/TopLocationsWidgetConfiguration';
import { GroupPerTimeType } from '@/modules/dashboards/components/widgets/events-over-time-widget/group-per-time-type.enum';
import { EventSourceType, EventType, GpsSourceType } from '@/modules/events/api/events/event.contracts';
import { date } from 'zod';

export const nlTranslations: ResourceLanguage = {
  translation: {
    applicationLog: {
      title: 'Systeem log',
      messageId: 'Bericht-ID',
      logItem_one: 'Logitem',
      logItem_other: 'Logitems',
      occurredAt: 'Opgetreden op',
      message: 'Bericht',

      severity: {
        title: 'Ernst',
        error: 'Fout',
        warning: 'Waarschuwing',
        info: 'Info',
        all: 'Alle ernstniveaus',
      },
      category: {
        title: 'Categorie',
        import: 'Import',
        eventProcessing: 'Eventverwerking',
        other: 'Overig',
        all: 'Alle categorieën',
      },
    },
    balance_block: {
      title: 'Balansen',
      hierarchical: 'Hiërarchisch',
      individual: 'Individueel',
      total_balance: 'Totale Balans',
      total_balance_helper_text: 'Balans op deze locatie: {{balance}}, inclusief onderliggende locaties: {{children}}',
      incoming: 'Binnenkomend',
      incoming_helper_text: 'Binnenkomend op deze locatie: {{balance}}, inclusief onderliggende locaties: {{children}}',
      outgoing: 'Uitgaand',
      outgoing_helper_text: 'Uitgaand op deze locatie: {{balance}}, inclusief onderliggende locaties: {{children}}',
      return_rate: 'Retourpercentage',
      return_rate_tooltip: '((Uitgaand - Uitgaand uitgesloten retourlocaties) / Binnenkomend) * 100',
      staytime: 'Verblijftijd',
      staytime_helper_text: 'Stilstaande assets op deze locatie: {{balance}}, inclusief onderliggende locaties: {{children}}',
      [DisplayMode.HIERARCHICAL]: 'Hiërarchisch',
      [DisplayMode.INDIVIDUAL]: 'Individueel',
      location_balance_helper_text:
        'Deze rij toont de totale balans van deze locatie + alle {{count}} onderliggende locaties bij elkaar opgeteld.',
    },
    balances: 'Balansen',
    balance: 'Balans',
    hierarchical: 'Hiërarchisch',
    individual: 'Individueel',
    total_balance: 'Totale Balans',
    total_balance_helper_text: 'Balans op deze locatie: {{balance}}, inclusief onderliggende locaties: {{children}}',
    incoming: 'Binnenkomend',
    incoming_helper_text: 'Binnenkomend op deze locatie: {{balance}}, inclusief onderliggende locaties: {{children}}',
    outgoing: 'Uitgaand',
    outgoing_helper_text: 'Uitgaand op deze locatie: {{balance}}, inclusief onderliggende locaties: {{children}}',
    return_rate: 'Retourpercentage',
    return_rate_tooltip: '((Uitgaand - Uitgaand uitgesloten retourlocaties) / Binnenkomend) * 100',
    staytime: 'Verblijftijd',
    staytime_helper_text: 'Stilstaande assets op deze locatie: {{balance}}, inclusief onderliggende locaties: {{children}}',
    [DisplayMode.HIERARCHICAL]: 'Hiërarchisch',
    [DisplayMode.INDIVIDUAL]: 'Individueel',
    location_balance_helper_text: 'Deze rij toont de totale balans van deze locatie + alle {{count}} onderliggende locaties bij elkaar.',
    dashboard: {
      top_asset_types_metric: {
        [TopAssetTypesMetric.assetCount]: 'Aantal Assets',
        [TopAssetTypesMetric.assetTotalMonetaryValue]: 'Totale Monetaire Waarde van Assets',
      },
      top_locations_metric: {
        [TopLocationsMetric.assetCount]: 'Aantal Assets',
        [TopLocationsMetric.assetTotalMonetaryValue]: 'Totale Monetaire Waarde van Assets',
      },
      group_per_time_type: {
        [GroupPerTimeType.DAY]: 'Dag',
        [GroupPerTimeType.HOUR]: 'Uur',
        [GroupPerTimeType.WEEK]: 'Week',
      },
      balance_over_time_period: {
        last_30_days: 'Laatste 30 dagen',
        last_90_days: 'Laatste 90 dagen',
      },
      dashboard: 'Dashboard',
      home: 'Home',
      admin: 'Beheer',
      create: {
        title: 'Nieuw Dashboard aanmaken',
        description: 'Voer een naam in voor het nieuwe Dashboard:',
        nameLabel: 'Naam',
        helperText: 'Minimaal 3 karakters',
        cancel: 'Annuleren',
        createButton: 'Aanmaken',
        success: 'Dashboard aangemaakt!',
      },
      rename: {
        title: 'Dashboard hernoemen',
        description: 'Voer de nieuwe naam in voor het Dashboard:',
        newNameLabel: 'Nieuwe naam',
        helperText: 'Minimaal 3 karakters',
        cancel: 'Annuleren',
        renameButton: 'Hernoemen',
        success: 'Dashboard hernoemd!',
      },
      widget: {
        deleteButton: 'Widget verwijderen',
        editWidget: "Bewerk widget '{{widgetName}}'",
        closeAriaLabel: 'sluiten',
        editConfiguration: 'Bewerk hieronder de configuratie van deze widget.',
      },
      widgetCatalog: {
        addWidgets: 'Widgets toevoegen',
        closeAriaLabel: 'sluiten',
        dragDropInstruction: 'Sleep een widget vanuit de onderstaande lijst naar het dashboard om deze toe te voegen.',
      },
      balanceOverTimeWidget: {
        noBalancesFound: 'Geen balansen gevonden.',
        title: 'Locatiebalans in de tijd',
        description: 'Toont de balansen van een locatie in de loop van de tijd, gegroepeerd per opgegeven tijdsinterval.',
      },
      balanceOverTimeWidgetConfiguration: {
        title: 'Balans per dag',
      },
      countWidget: {
        title: 'Aantal Assets',
        description: 'Toont het aantal assets op basis van Locatie- of Label-filters.',
      },
      countWidgetConfiguration: {
        title: 'Totaal aantal Assets',
      },
      eventsOverTimeByTypeWidget: {
        title: 'Events in de tijd per Type',
        description: 'Toont het totaal aantal events van de laatste 7 dagen, gegroepeerd op type.',
      },
      eventsOverTimeByTypeWidgetConfiguration: {
        title: 'Events in de tijd per Type',
      },
      eventsOverTimeWidget: {
        title: 'Events in de tijd',
        description: 'Toont het totaal aantal events, gegroepeerd volgens een gekozen tijdsinterval.',
      },
      eventsOverTimeWidgetConfiguration: {
        title: 'Events per dag',
      },
      mapWidget: {
        title: 'Kaart',
        description: 'Toont de positie van uw assets op de kaart.',
      },
      mapWidgetConfiguration: {
        title: 'Kaart',
      },
      systemTotalWidget: {
        title: 'Totaal',
        description: 'Toont het totaal aantal Assets, Trackers, Events of Locaties in het systeem.',
      },
      systemTotalWidgetConfiguration: {
        title: 'Totaal',
      },
      topAssetTypesWidget: {
        title: 'Top Assettypes',
        description: 'Een top 10 van Assettypes op basis van een door u geselecteerde metric.',
      },
      topAssetTypesWidgetConfiguration: {
        title: 'Top Assettypes',
      },
      topLocationsWidget: {
        title: 'Top Locaties',
        description: 'Een top 10 van locaties op basis van een door u gekozen metric (bijv. aantal assets).',
      },
      topLocationsWidgetConfiguration: {
        title: 'Top Locaties op basis van Aantal Assets',
      },
      deleteConfirmation: {
        questionText: 'Weet u zeker dat u dit dashboard wilt verwijderen?',
        questionTitle: 'Dashboard verwijderen',
      },
      header: 'Dashboards',
      addTab: '+ Dashboard',
      actions: {
        showFullscreen: 'Volledig scherm weergeven',
        editWidgets: 'Widgets bewerken',
        deleteDashboard: 'Dashboard verwijderen',
        renameDashboard: 'Dashboard hernoemen',
        saveDashboard: 'Dashboard opslaan',
      },
    },

    event: {
      title: 'Events',
      all: 'Alle',
      handheld: 'Handheld',
      event_one: 'Event',
      event_other: 'Events',
      occurred_at: 'Opgetreden op',
      source: 'Bron',
      coordinates_source: 'Coördinatenbron',
      coordinates_accuracy: 'Coördinatennauwkeurigheid',
      asset_code: 'Assetcode',
      tracker_type: 'Trackertype',
      location: 'Locatie',
      tracker: 'Tracker',
      device_name: 'Apparaatnaam',
      rfids: "RFID's",
      barcodes: 'Barcodes',
      mutations: 'Mutaties',
      assets: 'Assets',
      trackers: 'Trackers',

      type: {
        title: 'Type',
        [EventType.Observation]: 'Observatie',
        [EventType.Incoming]: 'Binnenkomend',
        [EventType.Outgoing]: 'Uitgaand',
        [EventType.Inventory]: 'Inventaris',
        [EventType.ADMINISTRATIVE]: 'Administratief',
        undefined: 'Onbekend',
        all: 'Alle Eventtypes',
      },

      event_source: {
        [EventSourceType.GATE]: 'Poort',
        [EventSourceType.MANUAL]: 'Handmatig',
        [EventSourceType.TRACKER]: 'Tracker',
        [EventSourceType.IMPORTER]: 'Importeur',
        [EventSourceType.HANDHELD]: 'Handheld',
        undefined: 'Onbekend',
      },

      gps_source: {
        [GpsSourceType.GPS]: 'GPS',
        [GpsSourceType.SIGFOX_ATLAS_NATIVE]: 'Sigfox-triangulatie',
        [GpsSourceType.CERTIFIED_LOCATION_WIFI]: 'Gecertificeerde WiFi',
        [GpsSourceType.WIFI]: 'Algemene WiFi',
        [GpsSourceType.CERTIFIED_LOCATION_BLE]: 'Gecertificeerde BLE',
        [GpsSourceType.CELLULAR]: 'Cellulaire triangulatie',
        [GpsSourceType.LOCATION_CODE]: 'Locatiecode',
        undefined: 'Onbekend',
        null: '',
      },

      errors: {
        date_from_after: 'Begindatum kan niet na de einddatum liggen',
        date_to_before: 'Einddatum kan niet vóór de begindatum liggen',
      },
    },
    importer: {
      upload_a_file: 'Bestand uploaden',
      import_error: {
        NON_UNIQUE_ASSET_CODE: 'NIET_UNIEKE_ASSETCODE',
        NON_UNIQUE_TRACKER_BARCODE: 'NIET_UNIEKE_TRACKER_BARCODE',
        NON_UNIQUE_TRACKER_EPC: 'NIET_UNIEKE_TRACKER_EPC',
        NON_UNIQUE_TRACKER_DEVICE_ID: 'NIET_UNIEKE_TRACKER_DEVICE_ID',
        NON_EXISTENT_ASSET_TYPE_CODE: 'Assettype bestaat niet',
        BARCODE_AND_EPC_CODE_AND_UNATAG_ID_NOT_DEFINED: 'BARCODE_EN_EPC_CODE_EN_UNATAG_ID_NIET_GEDEFINIEERD',
        NON_EXISTENT_LOCATION_CODE: 'Locatie bestaat niet',
      },
      next: 'Volgende',
      previous: 'Vorige',
      preview: {
        preview: 'Voorbeeld',
        file_contains_errors: 'Het bestand bevat {{count}} fouten. Los deze op en probeer opnieuw te uploaden.',
        file_upload_failed: 'Uploaden mislukt. Probeer het nogmaals.',
      },
      upload: {
        file_details: 'Bestandsdetails',
        files_you_can_imported: 'Selecteer een CSV-bestand om te importeren.',
        file_size: 'Grootte',
        select_file: 'Selecteer bestand',
        select_csv_file: 'Selecteer CSV-bestand',
        change_file: 'Bestand wijzigen',
      },
      import: {
        import: 'Importeren',
      },
      asset_import: {
        title: 'Assets',
        description: 'Importeer assets inclusief hun Barcode-, EPC- of Unatag-code.',
      },
      location_import: {
        title: 'Locaties',
        description: 'Importeer Locaties',
      },
      parent_location_import: {
        title: 'Bovenliggende Locaties',
        description: 'Stel bovenliggende Locaties in',
      },
    },
    assets_map: 'Assetkaart',
    legend: 'Legenda',
    in_transit: 'In transit',
    on_location: 'Op locatie',
    setting: {
      api: {
        label: 'API',
        title: 'API-instellingen',
        general_description: 'Algemene API-instellingen',
        url_title: 'API-URL',
        url_description: 'De openbare Posito API-URL voor integraties.',
        documentation_title: 'API-documentatie',
        documentation_description: 'De Open API-documentatie van de API.',
        keys_title: 'API-sleutels',
        keys_description: 'Maak en beheer API-sleutels voor toegang tot de openbare API.',
        manage_keys_title: 'API-sleutels beheren',
        manage_keys_description: 'Hier kunt u API-sleutels aanmaken, hernoemen, in-/uitschakelen en verwijderen.',
        add: 'API-sleutel toevoegen',
        add_title: 'API-sleutel toevoegen',
        add_name_label: 'Naam',
        add_description: 'Voer een naam in voor de nieuwe API-sleutel',
        add_success: 'API-sleutel succesvol toegevoegd',
        add_name_validation: 'Naam moet tussen 1 en 25 tekens lang zijn',
        update_success: 'API-sleutel succesvol bijgewerkt',
        delete: 'API-sleutel verwijderen',
        delete_confirmation: 'Weet u zeker dat u deze API-sleutel wilt verwijderen?',
        delete_success: 'API-sleutel succesvol verwijderd',
        name: 'Naam',
        created: 'Aangemaakt',
        key: 'Sleutel',
        active: 'Actief',
      },
      connector: {
        title: 'Connector-instellingen',
        unaconnect_title: 'UnaConnect',
        unaconnect_description: 'Een portaal dat Tracker-events naar Posito kan sturen.',
        unaconnect_url_title: 'UnaTag Webhook-URL',
        unaconnect_url_description: 'De URL om UnaTag-events naartoe te sturen. Configureer dit in UnaConnect.',
        nedap_title: 'Nedap Harmony',
        nedap_description: 'Een portaal dat Tracker-events naar Posito kan sturen.',
        nedap_url_title: 'Nedap Harmony Webhook-URL',
        nedap_url_description: 'De URL om Nedap Harmony-events naartoe te sturen. Configureer dit in een Nedap Harmony Hook.',
        epc_title: 'EPC Events',
        epc_description: 'Een eindpunt om EPC-events naartoe te sturen.',
        epc_url_title: 'EPC Webhook-URL',
        epc_url_description: 'De URL om EPC-events naartoe te sturen. Configureer dit in uw eigen software.',
      },
      event: {
        title: 'Event-instellingen',
        creation_title: 'Eventcreatie',
        creation_description: 'Bepaal hoe events worden aangemaakt.',
        auto_create_trackers_title: 'Automatisch Trackers aanmaken',
        auto_create_trackers_description:
          'Als een Tracker wordt waargenomen (bijv. gescand) die nog niet bestaat, wordt er automatisch een nieuwe Tracker aangemaakt.',
        update_success: 'Instelling bijgewerkt: Automatisch Trackers aanmaken',
      },
    },
    trackers: 'Trackers',
    tracker_add: 'Tracker toevoegen',
    tracker_new: 'Nieuwe Tracker',
    tracker_device_identifier: 'Apparaat-ID',
    tracker_unique_identifier: 'Unieke ID',
    tracker: 'Tracker',
    trackers_one: 'Tracker',
    trackers_other: 'Trackers',
    tracker_unknown: 'Onbekende Tracker',
    tracker_created: 'Tracker aangemaakt',
    tracker_updated: 'Tracker bijgewerkt',
    tracker_deleted: 'Tracker verwijderd',
    tracker_delete_confirm: 'Weet u zeker dat u deze tracker wilt verwijderen?',
    error_creating_tracker: 'Fout bij aanmaken Tracker',
    error_updating_tracker: 'Fout bij bijwerken Tracker',
    error_deleting_tracker: 'Fout bij verwijderen Tracker',
    error_duplicate_code: 'De code is al in gebruik en moet uniek zijn. Voer een andere code in.',
    error_no_permission_to_edit_trackers: 'U hebt geen toestemming om Trackers te bewerken',
    tracker_in_use_barcode: 'De Barcode is al in gebruik en moet uniek zijn. Voer een andere in.',
    tracker_in_use_epc: 'De EPC is al in gebruik en moet uniek zijn. Voer een andere in.',
    tracker_in_use_device_id: 'De Device-ID is al in gebruik en moet uniek zijn. Voer een andere in.',
    tracker_linked_to_other_asset:
      'Deze Tracker is momenteel gekoppeld aan Asset met code {{assetCode}}. Het opslaan van deze Asset zal de Tracker ontkoppelen van Asset {{assetCode}} en koppelen aan deze Asset.',
    no_trackers_added: 'Nog geen Trackers toegevoegd.',
    barcode: 'Barcode',
    rfid: 'RFID',
    unatag: 'UnaTag',
    ble: 'BLE',
    assets: 'Assets',
    sign_in: 'Inloggen',
    search: 'Zoeken',
    filter: 'Filteren',
    filters: 'Filters',
    unlink: 'Ontkoppelen',
    delete: 'Verwijderen',
    save: 'Opslaan',
    no_data: 'Geen gegevens',
    image_upload: 'Afbeelding uploaden',
    image_upload_new: 'Nieuwe afbeelding uploaden',
    image_remove: 'Afbeelding verwijderen',
    showing_first: 'Toont de eerste {{amount}}',
    details: 'Details',
    unknown: 'Onbekend',
    at: 'op',
    total: 'Totaal',
    totals: 'Totalen',
    code: 'Code',
    type: 'Type',
    identifier: 'Identificatie',
    name: 'Naam',
    overview: 'Overzicht',
    export_excel: 'Exporteren naar Excel',
    refresh: 'Vernieuwen',
    select_all: 'Alles selecteren',
    deselect_all: 'Alles deselecteren',
    occurred_at: 'Opgetreden op',
    last_seen: 'Laatst gezien',
    general: 'Algemeen',
    error_loading_data: 'Fout bij laden gegevens',
    error_loading_data_verbose: 'Er is een fout opgetreden bij het laden van de gegevens.',
    error_occurred: 'Er is een fout opgetreden',
    cancel: 'Annuleren',
    copied: 'Gekopieerd!',
    or: 'Of',
    events: 'Events',
    asset_type: 'Assettype',
    create: 'Aanmaken',
    clear_selection: 'Selectie wissen',
    toggle_dark_mode: 'Donkere modus wisselen',
    dark_mode: 'Donkere Modus',
    configuration: 'Configuratie',
    insights: 'Inzichten',
    dashboards: 'Dashboards',
    map: 'Kaart',
    operations: 'Gebeurtenis',
    setup: 'Instellingen',
    locations: 'Locaties',
    scan_a_barcode: 'Scan een Barcode',
    position_the_barcode_in_the_center_of_the_screen: 'Plaats de barcode in het midden van het scherm.',
    media_devices_not_supported: 'Media-apparaten worden niet ondersteund door deze browser.',
    barcode_detector_not_supported: 'Barcodedetector wordt niet ondersteund door deze browser.',
    create_new_or_use_existing: 'Maak Nieuw of Gebruik Bestaand',
    create_new: 'Nieuw Aanmaken',
    use_existing: 'Bestaand Gebruiken',
    link: 'Koppelen',
    link_a_tracker_to_an_asset: 'Koppel een Tracker aan een Asset',
    select_asset_type_and_create_new_or_connect_existing:
      'Selecteer een Assettype en maak een nieuwe Asset en/of Tracker aan, of koppel aan een bestaande.',
    asset_code: 'Assetcode',
    existing_trackers: 'Bestaande Trackers',
    no_existing_trackers: 'Geen bestaande Trackers',
    add_trackers: 'Trackers toevoegen',
    asset_not_found: 'Asset niet gevonden',
    error_fetching_existing_asset: 'Fout bij ophalen bestaande Asset',
    no_asset_selected: 'Geen Asset geselecteerd',
    no_asset_type_selected: 'Geen Assettype geselecteerd',
    no_asset_code_entered: 'Geen Assetcode ingevoerd',
    no_tracker_identifier_entered: 'Geen Tracker-ID ingevoerd',
    error_fetching_existing_trackers: 'Fout bij koppelen Asset aan Tracker, kon bestaande Trackers niet ophalen',
    barcode_tracker_already_linked: 'Barcode Tracker is al gekoppeld aan een andere Asset, ontkoppel eerst op de desktopsite',
    rfid_tracker_already_linked: 'RFID Tracker is al gekoppeld aan een andere Asset, ontkoppel eerst op de desktopsite',
    unatag_tracker_already_linked: 'UnaTag Tracker is al gekoppeld aan een andere Asset, ontkoppel eerst op de desktopsite',
    ble_tracker_already_linked: 'BLE Tracker is al gekoppeld aan een andere Asset, ontkoppel eerst op de desktopsite',
    asset_code_already_exists: 'Er bestaat al een Asset met deze code',
    error_fetching_existing_assets: 'Fout bij koppelen van Asset aan Tracker, kon bestaande Assets niet ophalen',
    error_creating_barcode_tracker: 'Fout bij koppelen van Asset aan Tracker, kon nieuwe Barcode Tracker niet aanmaken',
    error_creating_rfid_tracker: 'Fout bij koppelen van Asset aan Tracker, kon nieuwe RFID Tracker niet aanmaken',
    error_creating_unatag_tracker: 'Fout bij koppelen van Asset aan Tracker, kon nieuwe UnaTag Tracker niet aanmaken',
    error_creating_ble_tracker: 'Fout bij koppelen van Asset aan Tracker, kon nieuwe BLE Tracker niet aanmaken',
    no_trackers_created: 'Fout bij koppelen van Asset aan Tracker, er konden geen Trackers worden aangemaakt',
    error_creating_new_asset: 'Fout bij koppelen van Asset aan Tracker, kon geen nieuwe Asset aanmaken',
    error_linking_tracker_to_asset: 'Fout bij koppelen van Asset aan Tracker, kon Tracker niet koppelen',
    error_linking_asset_to_tracker: 'Fout bij koppelen van Asset aan Tracker',
    asset_linked_to_trackers: 'Asset gekoppeld aan Tracker(s)',
    showing_most_recent_events: 'Toont de {{count}} meest recente Events.',
    first_event_date: 'Eerste Eventdatum',
    last_event_date: 'Laatste Eventdatum',
    stay_time: 'Verblijftijd',
    tracker_n: 'Tracker {{number}}',
    no_gps_location_found: 'Geen GPS-locatie gevonden.',
    unarchive_asset_title: 'Asset de-archiveren',
    unarchive_asset_text:
      'De assetcode die u probeert te bewerken wordt al gebruikt door een gearchiveerde Asset. Wilt u deze Asset de-archiveren?',
    unarchive_asset_type_title: 'Assettype de-archiveren',
    unarchive_asset_type_text:
      'De assettypecode die u probeert te bewerken wordt al gebruikt door een gearchiveerd Assettype. Wilt u dit Assettype de-archiveren?',
    asset_type_with_code_already_exists: 'Er bestaat al een Assettype met deze code',

    unarchive_location_title: 'Locatie de-archiveren',
    unarchive_location_text:
      'De locatiecode die u probeert te bewerken wordt al gebruikt door een gearchiveerde Locatie. Wilt u deze Locatie de-archiveren?',
    location_with_code_already_exists: 'Er bestaat al een Locatie met deze code',
    unarchive: 'De-archiveren',
    current_location: 'Huidige Locatie',
    link_a_tracker: 'Koppel een Tracker',
    create_new_tracker: 'Nieuwe Tracker aanmaken',
    delete_confirmation_question:
      "Weet u zeker dat u deze Asset wilt verwijderen? De Trackers die aan deze Asset gekoppeld zijn, worden niet verwijderd, maar wel ontkoppeld. Deze Asset wordt verplaatst naar de 'Gearchiveerde' system-locatie.",
    error_deleting_asset: 'Fout bij het verwijderen van Asset',
    asset_deleted: 'Asset verwijderd',
    no_permission_edit_assets: 'U hebt geen toestemming om Assets te bewerken',
    duplicate_code: 'De code is al in gebruik en moet uniek zijn. Voer een andere code in.',
    error_creating_asset: 'Fout bij aanmaken Asset',
    error_updating_asset: 'Fout bij bijwerken Asset',
    asset_created: 'Asset aangemaakt',
    asset_updated: 'Asset bijgewerkt',
    linking_trackers_warning:
      'De Asset is aangemaakt, maar er ging iets mis bij het koppelen van de Trackers. Controleer de Asset om te zien of de juiste Trackers gekoppeld zijn.',
    new_asset: 'Nieuwe Asset',
    asset_with_code: 'Asset - {{code}}',
    history: 'Geschiedenis',
    tablist_aria_label: 'Asset Detail Tabbladen',
    minimum_stay_time_days: 'Minimale verblijftijd (dagen)',
    move_assets: 'Assets verplaatsen',
    assets_moved_successfully: 'Assets succesvol verplaatst',
    failed_to_move_assets: 'Fout bij verplaatsen van assets',
    error_moving_assets: 'Fout bij verplaatsen van Assets',
    move_assets_to_location_title: 'Verplaats {{count}} Asset(s) naar Locatie...',
    move: 'Verplaatsen',
    no_permission_to_edit_users: 'U hebt geen toestemming om Gebruikers te bewerken',
    error_creating_user: 'Fout bij aanmaken Gebruiker',
    user_created: 'Gebruiker aangemaakt',
    error_updating_user: 'Fout bij bijwerken Gebruiker',
    user_updated: 'Gebruiker bijgewerkt',
    no_data_error: 'Geen datafout',
    error_deleting_user: 'Fout bij verwijderen Gebruiker',
    user_deleted: 'Gebruiker verwijderd',
    email: 'E-mail',
    password: 'Wachtwoord',
    password_helper_text: 'Voer een wachtwoord in om te wijzigen, laat leeg om geen wijziging door te voeren.',
    confirm_delete_user: 'Weet u zeker dat u deze Gebruiker wilt verwijderen?',
    this_field_has_to_be_filled: 'Dit veld moet worden ingevuld.',
    this_is_not_a_valid_email: 'Dit is geen geldig e-mailadres.',
    you_must_select_a_role: 'U moet een rol selecteren.',
    password_with_minimum_characters_required: 'Een wachtwoord met minimaal 6 tekens is vereist',
    user_avatar: 'Avatar van {{name}}',
    active: 'Actief',
    edit: 'Bewerken',
    users: 'Gebruikers',
    roles: 'Rollen',
    new_user: 'Nieuwe Gebruiker',
    user_name: 'Gebruiker - {{name}}',
    user_tabs: 'Gebruikerstabbladen',
    add_user: 'Gebruiker toevoegen',
    view_dashboards: 'Dashboards bekijken',
    allows_user_to_view_dashboards: 'Sta gebruiker toe Dashboards te bekijken.',
    edit_dashboards: 'Dashboards bewerken',
    allows_user_to_create_edit_delete_dashboards: 'Sta gebruiker toe Dashboards aan te maken, bewerken en verwijderen.',
    view_map: 'Kaart bekijken',
    allows_user_to_view_map: 'Sta gebruiker toe de Kaart te bekijken.',
    view_balances: 'Balansen bekijken',
    allows_user_to_view_balances: 'Sta gebruiker toe Balansen te bekijken.',
    view_events: 'Events bekijken',
    allows_user_to_view_events: 'Sta gebruiker toe Events te bekijken.',
    view_locations: 'Locaties bekijken',
    allows_user_to_view_locations: 'Sta gebruiker toe Locaties te bekijken.',
    edit_locations: 'Locaties bewerken',
    allows_user_to_create_edit_delete_locations: 'Sta gebruiker toe Locaties aan te maken, bewerken en verwijderen.',
    view_assets: 'Assets bekijken',
    allows_user_to_view_assets: 'Sta gebruiker toe Assets te bekijken.',
    edit_assets: 'Assets bewerken',
    allows_user_to_create_edit_delete_assets: 'Sta gebruiker toe Assets aan te maken, bewerken en verwijderen.',
    asset_types: 'Assettypes',
    view_asset_types: 'Assettypes bekijken',
    allows_user_to_view_asset_types: 'Sta gebruiker toe Assettypes te bekijken.',
    edit_asset_types: 'Assettypes bewerken',
    allows_user_to_create_edit_delete_asset_types: 'Sta gebruiker toe Assettypes aan te maken, bewerken en verwijderen.',
    view_trackers: 'Trackers bekijken',
    allows_user_to_view_trackers: 'Sta gebruiker toe Trackers te bekijken.',
    edit_trackers: 'Trackers bewerken',
    allows_user_to_create_edit_delete_trackers: 'Sta gebruiker toe Trackers aan te maken, bewerken en verwijderen.',
    devices: 'Apparaten',
    view_devices: 'Apparaten bekijken',
    allows_user_to_view_devices: 'Sta gebruiker toe Apparaten te bekijken.',
    edit_devices: 'Apparaten bewerken',
    allows_user_to_create_edit_delete_devices: 'Sta gebruiker toe Apparaten aan te maken, bewerken en verwijderen.',
    labels: 'Labels',
    view_labels: 'Labels bekijken',
    allows_user_to_view_labels: 'Sta gebruiker toe Labels te bekijken.',
    edit_labels: 'Labels bewerken',
    allows_user_to_create_edit_delete_labels: 'Sta gebruiker toe Labels aan te maken, bewerken en verwijderen.',
    event_settings: 'Event-instellingen',
    view_event_settings: 'Event-instellingen bekijken',
    allows_user_to_view_event_settings: 'Sta gebruiker toe Event-instellingen te bekijken.',
    edit_event_settings: 'Event-instellingen bewerken',
    allows_user_to_create_edit_delete_event_settings: 'Sta gebruiker toe Event-instellingen aan te maken, bewerken en verwijderen.',
    connectors: 'Connectors',
    view_connectors: 'Connectors bekijken',
    allows_user_to_view_connectors: 'Sta gebruiker toe Connectors te bekijken.',
    api_settings: 'API-instellingen',
    view_api_settings: 'API-instellingen bekijken',
    allows_user_to_view_api_settings: 'Sta gebruiker toe API-instellingen te bekijken.',
    edit_api_settings: 'API-instellingen bewerken',
    allows_user_to_edit_api_settings: 'Sta gebruiker toe API-instellingen te bewerken.',
    view_users: 'Gebruikers bekijken',
    allows_user_to_view_users: 'Sta gebruiker toe Gebruikers te bekijken.',
    edit_users: 'Gebruikers bewerken',
    allows_user_to_edit_users: 'Sta gebruiker toe Gebruikers te bewerken.',
    view_roles: 'Rollen bekijken',
    allows_user_to_view_roles: 'Sta gebruiker toe Rollen te bekijken.',
    edit_roles: 'Rollen bewerken',
    allows_user_to_create_edit_delete_roles: 'Sta gebruiker toe Rollen aan te maken, bewerken en verwijderen.',
    file_importer: 'Bestanden uploaden',
    use_file_importer: 'Bestandsimporteur gebruiken',
    allows_user_to_use_file_importer: 'Sta gebruiker toe de Bestandsimporteur te gebruiken.',
    application_log: 'Systeem log',
    view_application_log: 'Systeemlog bekijken',
    allows_user_to_view_application_log: 'Sta gebruiker toe de Systeem log te bekijken.',
    mobile: 'Mobiel',
    mobile_connect: 'Mobiel koppelen',
    allows_user_to_connect_trackers_to_assets_in_smartphone_view:
      'Sta gebruiker toe Trackers aan Assets te koppelen in de smartphoneweergave.',
    mobile_events: 'Mobiele Events',
    allows_user_to_view_events_in_smartphone_view: 'Sta gebruiker toe Events te bekijken in de smartphoneweergave.',
    actions: 'Acties',
    read_only_role_cannot_be_edited: 'Deze Rol is alleen-lezen en kan niet worden bewerkt, om te voorkomen dat u uzelf buitensluit.',
    role: 'Rol',
    base_data: 'Basisgegevens',
    settings: 'Instellingen',
    api: 'API',
    updated_setting: 'Instelling bijgewerkt',
    error_updating_setting: 'Fout bij bijwerken instelling',
    updated_setting_auto_create_trackers: "Instelling bijgewerkt: 'Automatisch Trackers aanmaken'",
    download_template: 'Template downloaden',
    start_import: 'Import starten',
    import_ready_message: 'U kunt nu de gegevens in Posito importeren. Klik op de onderstaande knop om het importproces te starten.',
    importing_data: 'Data importeren...',
    import_successful: 'Import succesvol',
    import_failed: 'Import mislukt',
    uploading_file: 'Bestand uploaden...',
    import: 'Importeren',
    asset_import: 'Assetimport',
    import_assets_and_associate_them_with_trackers: 'Importeer assets en koppel ze aan trackers.',
    assets_to_create: 'Assets om aan te maken',
    trackers_to_create: 'Trackers om aan te maken',
    valid: 'Geldig',
    invalid: 'Ongeldig',
    error_reason: 'Reden van fout',
    missing_asset_code: 'Ontbrekende assetcode',
    invalid_asset_type: 'Ongeldig assettype',
    unknown_error: 'Onbekende fout',
    non_unique_asset_code: 'NIET_UNIEKE_ASSETCODE',
    non_unique_tracker_barcode: 'NIET_UNIEKE_TRACKER_BARCODE',
    non_unique_tracker_epc: 'NIET_UNIEKE_TRACKER_EPC',
    non_unique_tracker_device_id: 'NIET_UNIEKE_TRACKER_DEVICE_ID',
    non_existent_asset_type_code: 'Assettype bestaat niet',
    barcode_and_epc_code_and_unatag_id_not_defined: 'BARCODE_EN_EPC_CODE_EN_UNATAG_ID_NIET_GEDEFINIEERD',
    non_existent_location_code: 'Locatie bestaat niet',
    location_import: 'Locatieimport',
    import_locations_into_the_system: 'Importeer locaties in het systeem.',
    locations_to_create: 'Locaties om aan te maken',
    location_code: 'Locatiecode',
    location_name: 'Locatienaam',
    missing_location_code: 'Ontbrekende locatiecode',
    missing_location_name: 'Ontbrekende locatienaam',
    duplicate_location_code: 'Dubbelen locatiecode',
    parent_location_import: 'Bovenliggende Locatieimport',
    import_parent_locations: 'Importeer bovenliggende locaties.',
    number_locations_to_assign_new_parent: 'Aantal Locaties om een (nieuwe) bovenliggende Locatie aan toe te wijzen',
    parent_location_code: 'Bovenliggende Locatiecode',
    child_location_code: 'Onderliggende Locatiecode',
    missing_parent_location_code: 'Ontbrekende bovenliggende locatiecode',
    missing_child_location_code: 'Ontbrekende onderliggende locatiecode',
    duplicate_parent_location_code: 'Dubbelen bovenliggende locatiecode',
    no_events_found: 'Geen events gevonden',
    for: 'Voor',
    asset: 'Asset',
    asset_one: 'Asset',
    asset_other: 'Assets',
    move_assets_to_another_type: 'Verplaats Assets naar een ander type',
    asset_type_cannot_be_deleted:
      'Het Assettype "{{assetTypeName}}" kan niet worden verwijderd omdat er nog Assets aan gekoppeld zijn. Verplaats deze Assets eerst naar een ander Assettype.',
    select_new_asset_type: 'Selecteer nieuw Assettype',
    failed_to_move_assets_try_again: 'Het verplaatsen van Assets is mislukt. Probeer het opnieuw of neem contact op met support.',
    add_asset_type: 'Assettype toevoegen',
    image: 'Afbeelding',
    description: 'Beschrijving',
    model_year: 'Modeljaar',
    width_mm: 'Breedte (mm)',
    height_mm: 'Hoogte (mm)',
    depth_mm: 'Diepte (mm)',
    volume_cubic_mm: 'Volume (kubieke mm)',
    monetary_value: 'Monetaire Waarde',
    number_of_assets: '# Assets',
    new_asset_type: 'Nieuw Assettype',
    current_balance: 'Huidige Balans',
    error_creating_asset_type: 'Fout bij aanmaken Assettype',
    error_updating_asset_type: 'Fout bij bijwerken Assettype',
    error_uploading_image: 'Fout bij uploaden afbeelding',
    error_removing_image: 'Fout bij verwijderen afbeelding',
    asset_type_created: 'Assettype aangemaakt',
    asset_type_updated: 'Assettype bijgewerkt',
    no_permission_edit_asset_types: 'U hebt geen toestemming om Assettypes te bewerken',
    asset_type_deleted: 'Assettype verwijderd',
    delete_asset_type_confirmation: 'Weet u zeker dat u dit Assettype wilt verwijderen?',
    error_asset_type_has_assets: 'Dit Assettype heeft nog Assets. Wijs deze eerst toe aan een ander Assettype.',
    error_deleting_asset_type: 'Fout bij verwijderen Assettype',
    asset_types_helper_text: 'Selecteer een of meer Assettypes.',
    add: 'Toevoegen',
    asset_type_code: 'Assettypecode',
    asset_type_name: 'Assettypenaam',
    from_location: 'Van Locatie',
    to_location: 'Naar Locatie',
    movement: 'Beweging',
    movements: 'Bewegingen',
    movement_one: 'Beweging',
    movement_other: 'Bewegingen',
    event_type: 'Eventtype',
    event_source: 'Eventbron',
    location: 'Locatie',
    assets_on_location: 'Assets op deze Locatie',
    recent_events: 'Recente Events',
    mobile_scanner: 'Mobiele Scanner',
    ble_gateway: 'BLE Gateway',
    all: 'Alle',
    mobile_scanners: 'Mobiele Scanners',
    ble_gateways: 'BLE Gateways',
    no_edit_device_permission: 'U hebt geen toestemming om Apparaten te bewerken',
    device_created: 'Apparaat aangemaakt',
    device_updated: 'Apparaat bijgewerkt',
    error_creating_device: 'Fout bij aanmaken apparaat',
    error_updating_device: 'Fout bij bijwerken apparaat',
    device: 'Apparaat',
    new_device: 'Nieuw Apparaat',
    brand: 'Merk',
    serial_number: 'Serienummer',
    add_device: 'Apparaat toevoegen',
    last_event_received_at: 'Laatst event ontvangen op',
    code_can_be_used_in_posito_scan_app: 'Deze code kan worden gebruikt in de Posito Scan-app om de app aan dit apparaat te koppelen.',
    battery_voltage: 'Batterijspanning',
    battery_voltage_updated_at: 'Batterijspanning laatst bijgewerkt op {{date}}',
    tracker_type: 'Trackertype',
    linked_trackers: 'Gekoppeld',
    unlinked_trackers: 'Niet gekoppeld',
    state: 'Status',
    sublocations: 'Sub-locaties',
    geofence: 'Geofence',
    geofence_select_title: 'Selecteer Geofence-adres',
    geofence_select_description:
      'Dit zijn de locaties die we konden vinden op basis van het adres. Selecteer er een om de geofence-locatie te bepalen. U kunt eventueel de geofence-radius aanpassen.',
    geofence_radius: 'Geofence-radius',
    location_select: 'Selecteer een locatie',
    location_select_description: 'Selecteer een locatie om op te filteren.',
    balance_over_time_for: "Balans in de tijd voor '{{locationName}}'",
    period: 'Periode',
    select_up_to_5_asset_types: 'Selecteer maximaal 5 Assettypes om weer te geven',
    delete_location_error_assets: 'Locatie heeft nog Assets. Verplaats deze eerst naar een andere locatie voordat u deze locatie verwijdert.',
    delete_location_error_system: 'Dit is een systeemlocatie en kan niet worden verwijderd.',
    delete_location_error_children:
      'Locatie heeft onderliggende locaties. Verwijder of verplaats deze eerst voordat u deze locatie verwijdert.',
    delete_location_error: 'Fout bij verwijderen locatie',
    delete_location_success: 'Locatie verwijderd',
    location_code_already_exists: 'Deze code is al in gebruik en moet uniek zijn. Voer een andere code in.',
    create_location_error: 'Fout bij aanmaken locatie',
    create_location_success: 'Locatie aangemaakt',
    update_location_error: 'Fout bij bijwerken locatie',
    update_location_success: 'Locatie bijgewerkt',
    delete_location_confirmation:
      'Weet u zeker dat u deze locatie wilt verwijderen? Events en bewegingen gekoppeld aan deze locatie worden niet verwijderd om de geschiedenis compleet te houden.',
    location_no_permission: 'U hebt geen toestemming om locaties te bewerken',
    parent_location: 'Bovenliggende Locatie',
    include_in_return_rate: 'Opnemen in retourpercentage',
    include_in_return_rate_tooltip:
      'Bepaalt of assets die op deze locatie aankomen worden meegerekend in het retourpercentage van de vertrek-locatie',
    address: 'Adres',
    country: 'Land',
    city: 'Stad',
    postal_code: 'Postcode',
    street_name: 'Straatnaam',
    street_number: 'Huisnummer',
    new_location: 'Nieuwe Locatie',
    system_location_alert: 'Dit is een systeemlocatie en kan niet worden verwijderd of van code worden veranderd.',
    system_location_tooltip:
      'Systeemlocaties worden door Posito aangemaakt en gebruikt voor bepaalde functionaliteiten. De locatiecode kan daarom niet worden gewijzigd.',
    more_info: 'Meer info',
    location_one: 'Locatie',
    location_other: 'Locaties',
    set_labels: 'Labels instellen',
    discard_changes: 'Wijzigingen negeren',
    label_fetch_error: 'Fout bij ophalen van labels. Probeer het later opnieuw.',
    add_label: 'Label toevoegen',
    select_label_value: 'Selecteer een labelwaarde',
    select_label: 'Selecteer een Label',
    manage_label_assignments: 'Labeltoewijzingen beheren',
    manage_label_assignments_empty_state_title: 'Geen labels gevonden',
    create_label: 'Label aanmaken',
    no_labels_assigned: 'Geen labels toegewezen aan selectie.',
    label_create_description: 'U kunt labels aanmaken en beheren in het configuratiegedeelte.',
    labels_description: 'Labels helpen u bij het groeperen en categoriseren van uw locaties.',
    create_a_label: 'Maak een Label aan',
    assign_label_to_location: 'en wijs het toe aan uw locaties.',
    label_description_example: 'Labels helpen uw middelen te organiseren',
    assignments: 'Toewijzingen',
    value: 'Waarde',
    values: 'Waardes',
    label_one: 'Label',
    label_other: 'Labels',
    new_label: 'Nieuw Label',
    label_created: 'Label aangemaakt',
    label_updated: 'Label bijgewerkt',
    label_deleted: 'Label verwijderd',
    label_name: 'Labelnaam',
    color: 'Kleur',
    add_value: 'Waarde toevoegen',
    label_value_caption: 'Voeg een of meer Labelwaardes toe om Locaties te categoriseren.',
    label_delete_confirmation: 'Weet u zeker dat u dit label wilt verwijderen?',
    label_values: 'Labelwaardes',
    label_helper_text: 'Selecteer een of meer labelwaardes.',
    label_error_permission: 'U hebt geen toestemming om labels te bewerken',
    label_error_duplicate: 'De naam is al in gebruik en moet uniek zijn. Voer een andere naam in.',
    label_error_creating: 'Fout bij aanmaken Label',
    label_error_updating: 'Fout bij bijwerken Label',
    label_error_deleting: 'Fout bij verwijderen Label',
    label_error_values_unique: 'Waardes moeten uniek zijn',
    label_error_values_empty: 'Waardes mogen niet leeg zijn',
    assets_total_elements: 'Assets ({{count}})',
    arrived: 'Aangekomen',
    pagination_result: '{{total}} {{entity}} gevonden',
    balance_per_asset_type: 'Balans per Assettype',
    arrived_at: 'Aangekomen op',
    export_to_excel: 'Exporteren naar Excel',
    close: 'Sluiten',
    error_loading_assets: 'Fout bij laden van assets',
    assets_at_location: 'Assets op locatie',
    model: 'Model',
    high: 'Hoog',
    medium: 'Middel',
    low: 'Laag',
    location_entered_at: 'Locatie binnengegaan op {{date}}',
    number_of_days: '{{day}} dagen',
    no_permission_to_view_page: 'U hebt geen toestemming om deze pagina te bekijken',
    week_short: 'Wk {{week}}',
    error_loading_settings: 'Fout bij laden instellingen',
    error_loading_user: 'Fout bij laden gebruiker',
    error_loading_user_settings: 'Fout bij laden gebruikersinstellingen',
    profile: 'Profiel',
    environment: 'Omgeving',
    development: 'Ontwikkeling',
    production: 'Productie',
    logout: 'Uitloggen',
    language: 'Taal',
    group_balance_over_time: 'Groeperen van balansen in de tijd',
    next: 'Volgende',
    previous: 'Vorige',
    count: 'Aantal',
    total_value: 'Totale waarde',
    no_locations_found: 'Geen locaties gevonden',
    no_asset_types_found: 'Geen assettypes gevonden',
    date_from: 'Begin datum',
    date_to: 'Eind datum',
    select_a_tracker: 'Selecteer een Tracker',
    enums: {
      event_source: {
        [EventSourceType.GATE]: 'Poort',
        [EventSourceType.MANUAL]: 'Handmatig',
        [EventSourceType.TRACKER]: 'Tracker',
        [EventSourceType.IMPORTER]: 'Importeur',
        [EventSourceType.HANDHELD]: 'Handheld',
        [EventSourceType.BLE_GATEWAY]: 'BLE Gateway',
        undefined: 'Onbekend',
      } as Record<EventSourceType, string>,
    },
  },
};
