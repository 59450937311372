import React, { useState } from 'react';
import { Popover } from '@mui/material';

interface FilterDropdownProps {
  isPopoverOpen: boolean;
  setIsPopoverOpen: (isOpen: boolean) => void;
  anchorElement: HTMLButtonElement | null;
  children: React.ReactNode;
}

const FilterDropdown = ({ isPopoverOpen, setIsPopoverOpen, anchorElement, children }: FilterDropdownProps) => {
  return (
    <Popover
      open={isPopoverOpen}
      onClose={() => setIsPopoverOpen(false)}
      anchorEl={anchorElement}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <div className="p-4 min-w-72">{children}</div>
    </Popover>
  );
};

export default FilterDropdown;
