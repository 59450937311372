import React, { useState } from 'react';
import { ExpandMoreOutlined } from '@mui/icons-material';
import { cn } from '@/lib/utils/utils';
import FilterDropdown from './FilterDropdown';
import { Button } from '@mui/material';

interface FilterItemProps {
  label: string;
  filter: React.ReactNode;
  isActive?: boolean;
}

const FilterItem = ({ label, filter, isActive }: FilterItemProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);

  const handleFilterButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
    setIsPopoverOpen(!isPopoverOpen);
  };

  return (
    <>
      <Button
        className={cn(
          'relative px-4 py-2 rounded-md flex items-center gap-2 transition-all',
          'text-gray-700 hover:bg-gray-200 dark:text-white dark:hover:bg-gray-700',
          isActive ? 'text-orange-500 dark:text-orange-500' : '',
        )}
        variant="text"
        onClick={handleFilterButtonClick}
      >
        <span>{label}</span>
        <ExpandMoreOutlined className={cn('transition-transform', isPopoverOpen && 'transform rotate-180')} />
      </Button>
      <FilterDropdown isPopoverOpen={isPopoverOpen} setIsPopoverOpen={setIsPopoverOpen} anchorElement={anchorElement}>
        {filter}
      </FilterDropdown>
    </>
  );
};

export default FilterItem;
