import { FunctionComponent } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useApiCall } from '@/hooks/useApiCall';
import { trackerService } from '../../api/trackers/tracker.service';
import { TrackerModel } from '../../types/TrackerModel';
import { GridApi, SortChangedEvent, GridReadyEvent } from 'ag-grid-community';
import { PagePaginationResultDto, PageSortOrder } from '@/lib/api/pagination.page.dto';
import { LinkedTrackerSearchParam, TrackerSortOption } from '../../api/trackers/tracker.contracts';
import { Button } from '@mui/material';
import { TabbedLayoutTopBar } from '@/modules/application/components/TabbedLayoutTopBar';
import TabbedLayoutTopBarHeader from '@/modules/application/components/TabbedLayoutTopBarHeader';
import { TabbedPageLayoutBody } from '@/modules/application/components/TabbedPageLayoutBody';
import { AddOutlined } from '@mui/icons-material';
import { TabbedLayout } from '@/modules/application/layouts/TabbedLayout';
import FilterBar from '@/components/filterbar/FilterBar';
import FilterBarSearchButton from '@/components/filterbar/FilterBarSearchButton';
import TextFilter from '@/components/filterbar/filters/TextFilter';
import { FilterValues } from '@/components/filterbar/FilterBarContext';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import RequirePermissionComponent from '@/components/permissions/RequirePermissionComponent';
import PagedResultDataText from '@/components/filterbar/PagedResultDataText';
import PaginationControls from '@/components/grid/PaginationControls';
import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import useQueryParamsFilters from '@/hooks/useQueryParamFilters';
import { CacheKey } from '@/providers/cache-provider/cache-key.enum';
import { useExportToExcel } from '@/hooks/useExportToExcel';
import { useTranslation } from '@/lib';
import { ColumnID } from '@/components/grid/column-ids';
import { TrackersGrid } from '../../components/TrackersGrid';
import TrackerTypeFilter from '@/components/filterbar/filters/TrackerTypeFilter';
import LinkedTrackerFilter from '@/components/filterbar/filters/LinkedTrackerFilter';

const TRACKER_FETCH_LIMIT = 1000;

export const TrackersPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const { setFiltersToUrl, filters } = useQueryParamsFilters(
    {
      pageNumber: searchParams.get('pageNumber') ? Number.parseInt(searchParams.get('pageNumber')!) : 1,
    },
    CacheKey.TRACKER_FILTERS,
  );

  const {
    data: trackerData,
    isLoading: trackerIsLoading,
    isError: trackerIsError,
    fetchData: fetchTrackers,
    setApiCallArg,
  } = useApiCall<PagePaginationResultDto<TrackerModel>>(async () => {
    return trackerService.get(
      {
        searchText: filters.current.searchText,
        trackerType: filters.current.trackerType,
        linkedAsset: parseLinkedAsset(filters.current.linkedTracker),
      },
      {
        page: filters.current.pageNumber,
        limit: TRACKER_FETCH_LIMIT,
        order: filters.current.sortDirection,
        sort: filters.current.sortOption,
      },
    );
  });

  const { exportToExcel } = useExportToExcel();

  const handleSearch = (filterValues: FilterValues, page?: number) => {
    setFiltersToUrl({
      ...filterValues,
      pageNumber: page ?? filters.current.pageNumber,
    });

    setApiCallArg(() =>
      trackerService.get(
        {
          searchText: filterValues.searchText,
          trackerType: filterValues.trackerType,
          linkedAsset: parseLinkedAsset(filterValues.linkedTracker),
        },
        {
          page: filterValues.pageNumber,
          limit: TRACKER_FETCH_LIMIT,
          order: filterValues.sortDirection,
          sort: filterValues.sortOption,
        },
      ),
    );
  };

  const onGridReady = (event: GridReadyEvent<TrackerModel>) => {
    // Apply initial sort state
    if (filters.current.sortOption && filters.current.sortDirection) {
      let columnId: string | undefined;

      switch (filters.current.sortOption) {
        case TrackerSortOption.TYPE:
          columnId = ColumnID.TRACKER_TYPE;
          break;
        case TrackerSortOption.UNIQUE_IDENTIFIER:
          columnId = ColumnID.TRACKER_NAME;
          break;
        case TrackerSortOption.ASSET_CODE:
          columnId = ColumnID.ASSET_CODE;
          break;
        case TrackerSortOption.BATTERY_VOLTAGE:
          columnId = ColumnID.TRACKER_BATTERY_VOLTAGE;
          break;
        case TrackerSortOption.LOCATION_CODE:
          columnId = ColumnID.LOCATION_CODE;
          break;
        case TrackerSortOption.MODEL:
          columnId = ColumnID.TRACKER_MODEL;
          break;
        default:
          break;
      }

      if (columnId) {
        event.api.applyColumnState({
          state: [
            {
              colId: columnId,
              sort: filters.current.sortDirection === PageSortOrder.ASC ? 'asc' : 'desc',
            },
          ],
          defaultState: { sort: null },
        });
      }
    }
  };

  const onSortChanged = (event: SortChangedEvent<TrackerModel>) => {
    if (event.columns && event.columns.length > 0 && event.source === 'uiColumnSorted') {
      const changedColumn = event.columns.find((col) => col.getSort());
      if (!changedColumn) {
        handleSearch(
          {
            ...filters.current,
            sortOption: undefined,
            sortDirection: undefined,
          },
          1,
        );
        return;
      }

      const changedColumnId = changedColumn.getColId();

      let sortOption: TrackerSortOption | undefined;
      switch (changedColumnId) {
        case ColumnID.TRACKER_TYPE:
          sortOption = TrackerSortOption.TYPE;
          break;
        case ColumnID.TRACKER_NAME:
          sortOption = TrackerSortOption.UNIQUE_IDENTIFIER;
          break;
        case ColumnID.ASSET_CODE:
          sortOption = TrackerSortOption.ASSET_CODE;
          break;
        case ColumnID.TRACKER_BATTERY_VOLTAGE:
          sortOption = TrackerSortOption.BATTERY_VOLTAGE;
          break;
        case ColumnID.LOCATION_CODE:
          sortOption = TrackerSortOption.LOCATION_CODE;
          break;
        case ColumnID.TRACKER_MODEL:
          sortOption = TrackerSortOption.MODEL;
          break;
        default:
          break;
      }

      let sortOrder: PageSortOrder | undefined;
      switch (changedColumn.getSort()) {
        case 'asc':
          sortOrder = PageSortOrder.ASC;
          break;
        case 'desc':
          sortOrder = PageSortOrder.DESC;
          break;
        default:
          sortOption = undefined;
          break;
      }

      handleSearch(
        {
          ...filters.current,
          sortOption,
          sortDirection: sortOrder,
        },
        1,
      );
    }
  };

  async function onExportToExcelClicked(gridApi: GridApi<TrackerModel> | undefined) {
    if (gridApi) {
      const data: TrackerModel[] = [];
      const dataPage1 = await trackerService.get(
        {
          searchText: filters.current.searchText,
          trackerType: filters.current.trackerType,
        },
        {
          page: 1,
          limit: TRACKER_FETCH_LIMIT,
          order: filters.current.sortDirection ?? PageSortOrder.ASC,
          sort: filters.current.sortOption,
        },
      );

      if (dataPage1.isSuccess) {
        data.push(...dataPage1.payload.data);
      }

      // Fetch additional pages if needed
      for (let i = 2; i <= (dataPage1.payload.totalPages || 1); i++) {
        const dataPage = await trackerService.get(
          {
            searchText: filters.current.searchText,
            trackerType: filters.current.trackerType,
          },
          {
            page: i,
            limit: TRACKER_FETCH_LIMIT,
            order: filters.current.sortDirection ?? PageSortOrder.ASC,
            sort: filters.current.sortOption,
          },
        );

        if (dataPage.isSuccess) {
          data.push(...dataPage.payload.data);
        }
      }

      if (data.length > 0) {
        exportToExcel(
          data.map((tracker) => ({
            type: tracker.type,
            uniqueIdentifier: tracker.uniqueIdentifier,
            assetCode: tracker.asset?.code,
            locationCode: tracker.location?.code,
            batteryVoltage: tracker.batteryVoltage,
            model: tracker.modelType,
          })),
          `trackers_${new Date().toISOString()}.xlsx`,
        );
      }
    }
  }

  const onRowDoubleClicked = (trackerId: string) => {
    navigate(`../detail/${trackerId}`);
  };

  const onPageChanged = (event: React.ChangeEvent<unknown>, page: number) => {
    handleSearch(
      {
        ...filters.current,
        pageNumber: page,
      },
      page,
    );
  };

  return (
    <TabbedLayout
      topBar={
        <TabbedLayoutTopBar
          actions={
            <RequirePermissionComponent permission={Permission.TRACKERS_EDIT}>
              <Button startIcon={<AddOutlined></AddOutlined>} onClick={() => navigate('../create')}>
                {t('tracker_add')}
              </Button>
            </RequirePermissionComponent>
          }
        >
          <TabbedLayoutTopBarHeader icon={null}>{t('trackers')}</TabbedLayoutTopBarHeader>
        </TabbedLayoutTopBar>
      }
    >
      <TabbedPageLayoutBody>
        {trackerIsError ? (
          <ErrorLoadingDataAlert />
        ) : (
          <div className="flex h-full flex-col">
            <div className="mb-2 flex items-center justify-between">
              <FilterBar onSearch={handleSearch} initialFilterValues={filters.current} isLoading={trackerIsLoading}>
                <div className="w-44">
                  <TextFilter label={t('search')} />
                </div>
                <div className="w-44">
                  <TrackerTypeFilter />
                </div>
                <div className="w-44">
                  <LinkedTrackerFilter />
                </div>
              </FilterBar>
            </div>
            <TrackersGrid
              data={trackerData?.data}
              isLoading={trackerIsLoading}
              onRowDoubleClicked={onRowDoubleClicked}
              onExportToExcelClicked={onExportToExcelClicked}
              onSortChanged={onSortChanged}
              onGridReady={onGridReady}
            />
            <div className="flex items-center gap-x-4">
              <PaginationControls
                isLoading={trackerIsLoading}
                totalPageCount={trackerData?.totalPages ?? 1}
                currentPage={filters.current.pageNumber ?? 1}
                totalElements={trackerData?.totalElements ?? 0}
                onChange={onPageChanged}
              />
              <PagedResultDataText data={trackerData} name={t('trackers', { count: trackerData?.totalElements ?? 0 })} />
            </div>
          </div>
        )}
      </TabbedPageLayoutBody>
    </TabbedLayout>
  );
};

function parseLinkedAsset(value: LinkedTrackerSearchParam | null | undefined): boolean | null {
  switch (value) {
    case LinkedTrackerSearchParam.LINKED:
      return true;
    case LinkedTrackerSearchParam.UNLINKED:
      return false;
    default:
      return null;
  }
}
