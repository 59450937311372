// FilterBar.tsx
import React, { FunctionComponent, PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import { FilterBarContext, FilterFieldName, FilterValues } from './FilterBarContext';
import useFilterBar from './useFilterBar';
import { isEqual } from 'lodash-es';
import { Button } from '@mui/material';
import { useTranslation } from '@/lib';
import { CloseOutlined, FilterAltOutlined } from '@mui/icons-material';
import FilterItem from './FilterBarItem';
import FilterBarSearchButton from './FilterBarSearchButton';
import { cn } from '@/lib/utils/utils';

interface FilterBarProps extends PropsWithChildren {
  onChange?: (filterValues: FilterValues) => void;
  onSearch?: (filterValues: FilterValues) => void;
  initialFilterValues?: FilterValues;
  showAsPopover?: boolean;
  size?: 'small' | 'medium';
  setMinHeight?: boolean;
  isLoading?: boolean;
  filterComponents?: { label: string; filterName: FilterFieldName; filter: React.ReactNode }[];
  showSearchButton?: boolean;
  className?: string;
}

const FilterBar: FunctionComponent<FilterBarProps> = ({
  onChange,
  onSearch,
  children,
  initialFilterValues,
  showAsPopover,
  size = 'medium',
  isLoading,
  filterComponents,
  showSearchButton = true,
  className,
}) => {
  const { filterValues, setFilterValue, triggerSearch, isFilterActive } = useFilterBar({ onSearch, initialFilterValues });
  const previousFilterValuesRef = useRef(filterValues);
  const { t } = useTranslation();

  // Emit the filter values on change
  useEffect(() => {
    if (onChange) {
      if (!isEqual(filterValues, previousFilterValuesRef.current)) {
        onChange(filterValues);

        previousFilterValuesRef.current = filterValues;
      }
    }
  }, [filterValues]);

  const filterItems = useMemo(() => {
    if (!filterComponents || filterComponents.length === 0) {
      return null;
    }
    return filterComponents.map((filterComponent, index) => (
      <FilterItem
        key={index}
        label={filterComponent.label}
        filter={filterComponent.filter}
        isActive={isFilterActive(filterComponent.filterName)}
      />
    ));
  }, [filterValues]);

  const hasActiveFilters = useMemo(() => {
    if (!filterComponents || filterComponents.length === 0) {
      return false;
    }
    return filterComponents.some((filterComponent) => isFilterActive(filterComponent.filterName));
  }, [filterValues]);

  const onResetFilters = () => {
    filterComponents?.forEach((filterComponent) => {
      setFilterValue(filterComponent.filterName, undefined);
    });
  };

  return (
    <FilterBarContext.Provider value={{ filterValues, setFilterValue, triggerSearch, isFilterActive }}>
      {showAsPopover ? (
        <div
          className={cn(
            'rounded-lg shadow-sm border bg-gray-100 border-gray-200 dark:bg-gray-800 dark:border-gray-600 flex items-center overflow-x-auto w-full',
            size === 'small' ? 'px-1' : 'p-1',
            className,
          )}
        >
          <div className="px-3 py-2 flex items-center gap-2 border-r border-gray-200 dark:border-gray-600">
            <FilterAltOutlined />
            {size === 'medium' && <span className="text-sm font-medium">{t('filters')}</span>}
          </div>
          <div className="flex items-center gap-1 px-2 flex-grow overflow-x-auto scrollbar-hide">{filterItems}</div>
          {hasActiveFilters && (
            <Button
              onClick={onResetFilters}
              variant="text"
              className="flex items-center gap-0.5 px-3 mr-2 text-xs text-gray-700 hover:text-orange-500 dark:text-white dark:hover:text-orange-500 transition-colors"
              size={size === 'small' ? 'small' : 'medium'}
            >
              <CloseOutlined className="scale-75" />
              <span>Reset</span>
            </Button>
          )}
          {showSearchButton && <FilterBarSearchButton size={size} isLoading={isLoading} />}
        </div>
      ) : (
        <div className="flex items-center gap-2">
          {children} {showSearchButton && <FilterBarSearchButton size={size} className="mt-1" isLoading={isLoading || false} />}
        </div>
      )}
    </FilterBarContext.Provider>
  );
};

export default FilterBar;
