import { useApiCall } from '@/hooks/useApiCall';
import { FunctionComponent, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { DisplayMode } from '@/types/display-mode.enum';
import LocationHierarchyToggleButton from '../LocationHierarchyToggleButton';
import { PagePaginationResultDto, PageSortOrder } from '@/lib/api/pagination.page.dto';
import PagedResultDataText from '@/components/filterbar/PagedResultDataText';
import PaginationControls from '@/components/grid/PaginationControls';
import useQueryParamsFilters from '@/hooks/useQueryParamFilters';
import { CacheKey } from '@/providers/cache-provider/cache-key.enum';
import { FilterValues } from '@/components/filterbar/FilterBarContext';
import { GridApi, GridReadyEvent, SortChangedEvent } from 'ag-grid-community';
import { ColumnID } from '@/components/grid/column-ids'; // Ensure this includes the necessary column IDs
import { useTranslation } from '@/lib';
import { MovementSortOption } from '@/modules/movements/api/movements.contracts';
import { MovementModel } from '@/modules/movements/types/MovementModel';
import { movementService } from '@/modules/movements/api/movements.service';
import { LocationMovementsGrid } from './LocationMovementsGrid';
import { useExportToExcel } from '@/hooks/useExportToExcel';
import dayjs from 'dayjs';

const MOVEMENT_FETCH_LIMIT = 1000;

export const LocationMovementsTab: FunctionComponent = () => {
  const { locationId } = useParams<{ locationId: string }>();
  const { t } = useTranslation();
  const { exportToExcel } = useExportToExcel();

  if (!locationId) {
    return null; // Or render an appropriate message
  }

  const locationIdNumber = Number.parseInt(locationId);

  const [searchParams] = useSearchParams();
  const { setFiltersToUrl, filters } = useQueryParamsFilters(
    {
      pageNumber: searchParams.get('pageNumber') ? Number.parseInt(searchParams.get('pageNumber')!) : 1,
      sortOption: MovementSortOption.OCCURRED_AT,
      sortDirection: PageSortOrder.DESC,
    },
    CacheKey.LOCATION_MOVEMENT_FILTERS,
  );

  const [displayMode, setDisplayMode] = useState<DisplayMode>(DisplayMode.INDIVIDUAL);

  const {
    data: movementData,
    isLoading: movementIsLoading,
    isError: movementIsError,
    fetchData: fetchMovements,
    setApiCallArg,
  } = useApiCall<PagePaginationResultDto<MovementModel>>(() => {
    return movementService.get(
      {
        locationId: locationIdNumber,
      },
      {
        page: filters.current.pageNumber,
        limit: MOVEMENT_FETCH_LIMIT,
        order: filters.current.sortDirection,
        sort: filters.current.sortOption,
      },
    );
  });

  const handleSearch = (filterValues: FilterValues, page?: number) => {
    setFiltersToUrl({
      ...filterValues,
      pageNumber: page ?? filters.current.pageNumber,
    });

    if (displayMode === DisplayMode.HIERARCHICAL) {
      setApiCallArg(() =>
        movementService.getHierarchical(
          {
            locationId: locationIdNumber,
          },
          {
            page: filterValues.pageNumber,
            limit: MOVEMENT_FETCH_LIMIT,
            order: filterValues.sortDirection,
            sort: filterValues.sortOption,
          },
        ),
      );
    } else {
      setApiCallArg(() =>
        movementService.get(
          {
            locationId: locationIdNumber,
          },
          {
            page: filterValues.pageNumber,
            limit: MOVEMENT_FETCH_LIMIT,
            order: filterValues.sortDirection,
            sort: filterValues.sortOption,
          },
        ),
      );
    }
  };

  const onPageChanged = (event: React.ChangeEvent<unknown>, page: number) => {
    handleSearch(
      {
        ...filters.current,
        pageNumber: page,
      },
      page,
    );
  };

  const onSortChanged = (event: SortChangedEvent<MovementModel>) => {
    if (event.columns && event.columns.length > 0 && event.source === 'uiColumnSorted') {
      const changedColumn = event.columns.find((col) => col.getSort());
      if (!changedColumn) {
        handleSearch(
          {
            ...filters.current,
            sortOption: undefined,
            sortDirection: undefined,
          },
          1,
        );
        return;
      }

      const changedColumnId = changedColumn.getColId();

      let sortOption: MovementSortOption | undefined;
      switch (changedColumnId) {
        case ColumnID.MOVEMENT_OCCURRED_AT:
          sortOption = MovementSortOption.OCCURRED_AT;
          break;
        case ColumnID.MOVEMENT_TYPE:
          sortOption = MovementSortOption.EVENT_TYPE;
          break;
        case ColumnID.ASSET_CODE:
          sortOption = MovementSortOption.ASSET_CODE;
          break;
        // Add other cases as necessary
        default:
          break;
      }

      let sortOrder: PageSortOrder | undefined;
      switch (changedColumn.getSort()) {
        case 'asc':
          sortOrder = PageSortOrder.ASC;
          break;
        case 'desc':
          sortOrder = PageSortOrder.DESC;
          break;
        default:
          sortOption = undefined;
          break;
      }

      handleSearch(
        {
          ...filters.current,
          sortOption,
          sortDirection: sortOrder,
        },
        1,
      );
    }
  };

  const onGridReady = (event: GridReadyEvent<MovementModel>) => {
    // Apply initial sort state
    if (filters.current.sortOption && filters.current.sortDirection) {
      let columnId: ColumnID | undefined;

      switch (filters.current.sortOption) {
        case MovementSortOption.OCCURRED_AT:
          columnId = ColumnID.MOVEMENT_OCCURRED_AT;
          break;
        case MovementSortOption.EVENT_TYPE:
          columnId = ColumnID.MOVEMENT_TYPE;
          break;
        case MovementSortOption.ASSET_CODE:
          columnId = ColumnID.ASSET_CODE;
          break;
        // Add other cases as necessary
        default:
          break;
      }

      if (columnId) {
        event.api.applyColumnState({
          state: [
            {
              colId: columnId,
              sort: filters.current.sortDirection === PageSortOrder.ASC ? 'asc' : 'desc',
            },
          ],
          defaultState: { sort: null },
        });
      }
    }
  };

  async function onExportToExcelClicked(gridApi: GridApi<MovementModel> | undefined) {
    if (gridApi) {
      const data: MovementModel[] = [];
      const dataPage1 = await movementService.get(
        {
          locationId: locationIdNumber,
        },
        {
          page: 1,
          limit: MOVEMENT_FETCH_LIMIT,
          order: filters.current.sortDirection,
          sort: filters.current.sortOption,
        },
      );

      if (dataPage1.isSuccess) {
        data.push(...dataPage1.payload.data);
      }

      // Fetch additional pages if needed
      for (let i = 2; i <= (dataPage1.payload.totalPages || 1); i++) {
        const dataPage = await movementService.get(
          {
            locationId: locationIdNumber,
          },
          {
            page: i,
            limit: MOVEMENT_FETCH_LIMIT,
            order: filters.current.sortDirection ?? PageSortOrder.ASC,
            sort: filters.current.sortOption,
          },
        );

        if (dataPage.isSuccess) {
          data.push(...dataPage.payload.data);
        }
      }

      if (data.length > 0) {
        exportToExcel(
          data.map((movement) => ({
            occurredAt: movement.occurredAt,
            type: movement.event.type,
            code: movement.asset.code,
            assetType: movement.asset.assetType.code,
            fromLocation: movement.fromLocation?.name,
            toLocation: movement.toLocation?.name,
            eventType: t(`event.type.${movement.event.type}`),
            eventSource: t(`enums.event_source.${movement.event.source}`),
          })),
          `location_${data[0].eventLocation.code}_movement_${dayjs().format('YYYY_MM_DD_HHmmss')}.xlsx`,
        );
      }
    }
  }

  const onDisplayModeChanged = (mode: DisplayMode) => {
    setDisplayMode(mode);
    handleSearch(filters.current, 1);
  };

  return (
    <div className="flex h-full flex-1 flex-grow max-w-[1920px] ">
      <div className="flex flex-col h-full w-full">
        <div className="text-md flex items-center justify-end w-full font-bold">
          <div className="text-xs flex items-center scale-75 origin-right">
            <LocationHierarchyToggleButton displayMode={displayMode} onChange={onDisplayModeChanged} reverseOrder />
          </div>
        </div>
        <LocationMovementsGrid
          data={movementData?.data}
          isLoading={movementIsLoading}
          onSortChanged={onSortChanged}
          onGridReady={onGridReady}
          onExportToExcelClicked={onExportToExcelClicked}
          // Add any other necessary props
        />

        <div className="flex items-center gap-x-4">
          <PaginationControls
            isLoading={movementIsLoading}
            totalPageCount={movementData?.totalPages ?? 1}
            currentPage={filters.current.pageNumber ?? 1}
            totalElements={movementData?.totalElements ?? 0}
            onChange={onPageChanged}
          />
          <PagedResultDataText data={movementData} name={t('movement', { count: movementData?.totalElements ?? 0 })} />
        </div>
      </div>
    </div>
  );
};
