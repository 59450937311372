import { DTO } from '@/types/dto-wrapper';
import { TimelineDTO, TimelineEventDTO, TimelineLocationDTO } from '../api/movements.contracts';
import { EventType } from '@/modules/events/api/events/event.contracts';

export class TimeLineModel extends DTO<TimelineDTO> {
  constructor(dto: TimelineDTO) {
    super(dto);
  }

  get location(): TimelineItemLocationModel {
    return TimelineItemLocationModel.fromDTO(this.dto.location);
  }

  get enterEvent(): TimelineItemEventModel {
    return TimelineItemEventModel.fromDTO(this.dto.enterEvent);
  }

  get leaveEvent(): TimelineItemEventModel | undefined {
    if (!this.dto.leaveEvent) {
      return undefined;
    }
    return TimelineItemEventModel.fromDTO(this.dto.leaveEvent);
  }

  static fromDTO(dto: TimelineDTO): TimeLineModel {
    return new TimeLineModel(dto);
  }

  toDTO(): TimelineDTO {
    return {
      location: this.location.toDTO(),
      enterEvent: this.enterEvent.toDTO(),
      leaveEvent: this.leaveEvent?.toDTO() ?? undefined,
    };
  }
}

export class TimelineItemLocationModel extends DTO<TimelineLocationDTO> {
  constructor(dto: TimelineLocationDTO) {
    super(dto);
  }

  get id(): number {
    return this.dto.id;
  }

  get name(): string {
    return this.dto.name;
  }

  static fromDTO(dto: TimelineLocationDTO): TimelineItemLocationModel {
    return new TimelineItemLocationModel(dto);
  }

  toDTO(): TimelineLocationDTO {
    return {
      id: this.id,
      name: this.name,
    };
  }
}

export class TimelineItemEventModel extends DTO<TimelineEventDTO> {
  constructor(dto: TimelineEventDTO) {
    super(dto);
  }

  get id(): number {
    return this.dto.id;
  }

  get occurredAt(): Date {
    return this.dto.occurredAt;
  }

  get type(): EventType {
    return this.dto.type;
  }

  static fromDTO(dto: TimelineEventDTO): TimelineItemEventModel {
    return new TimelineItemEventModel(dto);
  }

  toDTO(): TimelineEventDTO {
    return {
      id: this.id,
      occurredAt: this.occurredAt,
      type: this.type,
    };
  }
}
