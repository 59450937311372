import { ApiResponse } from '@/lib/api/api-response';
import { apiClient } from '@/lib/api/base-client';
import { PagePaginationQueryDtoParams, PagePaginationResultDto } from '@/lib/api/pagination.page.dto';
import { MovementModel } from '../types/MovementModel';
import { MovementFilterOptionsDTO, MovementReadDTO } from './movements.contracts';
import { TimeLineModel } from '../types/TimelineModel';

export class MovementService {
  public basePath = 'movement';

  async get(
    query: MovementFilterOptionsDTO,
    pagination: PagePaginationQueryDtoParams,
  ): Promise<ApiResponse<PagePaginationResultDto<MovementModel>>> {
    try {
      const applicationResponse = await apiClient.get<PagePaginationResultDto<MovementReadDTO>>(this.basePath, { ...query, ...pagination });
      return applicationResponse.processPagedPayload((payload) => {
        return payload.map(MovementModel.fromDTO);
      });
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getHierarchical(
    query: MovementFilterOptionsDTO,
    pagination: PagePaginationQueryDtoParams,
  ): Promise<ApiResponse<PagePaginationResultDto<MovementModel>>> {
    try {
      const applicationResponse = await apiClient.get<PagePaginationResultDto<MovementReadDTO>>(`${this.basePath}/hierarchical`, {
        ...query,
        ...pagination,
      });
      return applicationResponse.processPagedPayload((payload) => {
        return payload.map(MovementModel.fromDTO);
      });
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getTimeline(assetId: number, dateFrom: string, dateTo: string): Promise<ApiResponse<TimeLineModel[]>> {
    try {
      const applicationResponse = await apiClient.get<TimeLineModel[]>(`${this.basePath}/timeline/${assetId}`, {
        dateFrom: dateFrom,
        dateTo: dateTo,
      });
      return applicationResponse.processPayload((payload) => {
        return payload.map(TimeLineModel.fromDTO);
      });
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }
}

export const movementService = new MovementService();
