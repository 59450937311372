import { FunctionComponent, useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useApiCall } from '@/hooks/useApiCall';
import { locationService } from '@/modules/locations/api/locations/location.service';
import { LocationModel } from '@/modules/locations/types/LocationModel';
import { useUser } from '@/modules/users/contexts/UserContext';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import { useUserSettings } from '@/modules/users/contexts/UserSettingsContext';
import { UserSettingKey } from '@/modules/users/api/user-settings/user-setting.contracts';
import { DisplayMode } from '@/types/display-mode.enum';
import { Button, Checkbox, Tooltip } from '@mui/material';
import { useLayoutActions } from '@/providers/layout-actions-provider/LayoutActionsProvider';
import FilterBar from '@/components/filterbar/FilterBar';
import TextFilter from '@/components/filterbar/filters/TextFilter';
import FilterBarSearchButton from '@/components/filterbar/FilterBarSearchButton';
import LocationHierarchyToggleButton from '../components/LocationHierarchyToggleButton';
import useQueryParamsFilters from '@/hooks/useQueryParamFilters';
import { CacheKey } from '@/providers/cache-provider/cache-key.enum';
import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import { ActionBar } from '@/modules/application';
import ManageLabelAssignmentsDrawer from '@/modules/labels/components/ManageLabelAssignmentsDrawer';
import { LabelAssignmentEntityType } from '@/modules/labels/api/label.contracts';
import { FilterValues } from '@/components/filterbar/FilterBarContext';
import { TabbedPageLayoutBody } from '@/modules/application/components/TabbedPageLayoutBody';
import { TabbedPageLayout } from '@/modules/application/layouts/TabbedPageLayout';
import HierarchicalLocationsGrid from '../components/HierarchicalLocationsGrid';
import IndividualLocationsGrid from '../components/IndividualLocationsGrid';
import { LocationSortOption } from '../api/locations/location.contracts';
import { PageSortOption, PageSortOrder } from '@/lib/api/pagination.page.dto';
import { SortChangedEvent } from 'ag-grid-community';
import { ColumnID } from '@/components/grid/column-ids';
import { useTranslation } from '@/lib';

export const LocationsPage: FunctionComponent = () => {
  const { getUserSettingValueByKey, setUserSettingByKey } = useUserSettings();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { setActions } = useLayoutActions();
  const [displayMode, setDisplayMode] = useState<DisplayMode>(
    getUserSettingValueByKey(UserSettingKey.LOCATION_OVERVIEW_DISPLAY_MODE) ?? DisplayMode.INDIVIDUAL,
  );
  const { hasPermission } = useUser();
  const { t } = useTranslation();

  const { filters, setFiltersToUrl } = useQueryParamsFilters(
    {
      searchText: searchParams.get('searchText') ?? '',
      sortDirection: searchParams.get('sortDirection') as PageSortOrder,
      sortOption: searchParams.get('sortOption') as PageSortOption,
    },
    CacheKey.LOCATION_FILTERS,
  );

  const [hierarchicalSortOption, setHierarchicalSortOption] = useState<PageSortOption>(filters.current.sortOption ?? LocationSortOption.NAME);
  const [hierarchicalSortOrder, setHierarchicalSortOrder] = useState<PageSortOrder>(filters.current.sortDirection ?? PageSortOrder.ASC);
  const [individualSortOption, setIndividualSortOption] = useState<PageSortOption>(filters.current.sortOption ?? LocationSortOption.NAME);
  const [individualSortOrder, setIndividualSortOrder] = useState<PageSortOrder>(filters.current.sortDirection ?? PageSortOrder.ASC);

  const [selectedRows, setSelectedRows] = useState<LocationModel[]>([]);
  const [isManageLabelAssignmentsDrawerOpen, setIsManageLabelAssignmentsDrawerOpen] = useState(false);

  const { data, isLoading, isError, fetchData, setApiCallArg } = useApiCall<LocationModel[]>(() => {
    if (displayMode === DisplayMode.HIERARCHICAL) {
      return locationService.getAllHierarchical({ query: filters.current.searchText ?? '' });
    } else {
      return locationService.getAll({ query: filters.current.searchText ?? '' });
    }
  });

  useEffect(() => {
    if (hasPermission(Permission.LOCATIONS_EDIT)) {
      setActions(
        <Button variant="contained" onClick={onCreateLocationClicked}>
          {t('create')}
        </Button>,
      );
    }

    return () => {
      setActions('');
    };
  }, []);

  const handleSearch = (filterValues: FilterValues) => {
    setFiltersToUrl(filterValues);
    searchWithCurrentFilterValues(displayMode, filterValues);
  };

  function searchWithCurrentFilterValues(displayMode: DisplayMode, filterValues: FilterValues) {
    if (!filterValues) {
      return;
    }

    clearSelections();

    if (displayMode === DisplayMode.HIERARCHICAL) {
      setApiCallArg(() => locationService.getAllHierarchical({ query: filterValues.searchText ?? '' }));
    } else {
      setApiCallArg(() => locationService.getAll({ query: filterValues.searchText ?? '' }));
    }
  }

  function clearSelections() {
    setSelectedRows([]);
  }

  function onCreateLocationClicked() {
    navigate('../create');
  }

  function onLocationDisplayChanged(displayMode: DisplayMode) {
    setUserSettingByKey(UserSettingKey.LOCATION_OVERVIEW_DISPLAY_MODE, displayMode, false);
    setDisplayMode(displayMode);
    searchWithCurrentFilterValues(displayMode, filters.current);
  }

  const hasSelectedRows = selectedRows.length > 0;

  function onSetLabelsClicked() {
    setIsManageLabelAssignmentsDrawerOpen(true);
  }

  function onDeselectAllClicked() {
    clearSelections();
  }

  const onHierarchicalSortOptionChanged = (event: SortChangedEvent<LocationModel>) => {
    if (event.columns && event.columns.length > 0 && event.source === 'uiColumnSorted') {
      const changedColumn = event.columns[0];
      const changedColumnId = changedColumn.getColId();

      let sortOption: LocationSortOption | undefined;
      switch (changedColumnId) {
        case ColumnID.LOCATION_NAME:
          sortOption = LocationSortOption.NAME;
          break;
        case ColumnID.LOCATION_CODE:
          sortOption = LocationSortOption.CODE;
          break;
        case ColumnID.LOCATION_SUBLOCATIONS:
          sortOption = LocationSortOption.SUBLOCATIONS;
          break;
        default:
          break;
      }

      let sortOrder: PageSortOrder | undefined;
      switch (changedColumn.getSort()) {
        case 'asc':
          sortOrder = PageSortOrder.ASC;
          break;
        case 'desc':
          sortOrder = PageSortOrder.DESC;
          break;
        default:
          sortOption = undefined;
          break;
      }

      setFiltersToUrl({
        ...filters.current,
        sortOption,
        sortDirection: sortOrder,
      });
    }
  };

  function onIndividualSortOptionChanged(event: SortChangedEvent<LocationModel>) {
    if (event.columns && event.columns.length > 0 && event.source === 'uiColumnSorted') {
      const changedColumn = event.columns[0];
      const changedColumnId = changedColumn.getColId();

      let sortOption: LocationSortOption | undefined;
      switch (changedColumnId) {
        case ColumnID.LOCATION_NAME:
          sortOption = LocationSortOption.NAME;
          break;
        case ColumnID.LOCATION_CODE:
          sortOption = LocationSortOption.CODE;
          break;
        case ColumnID.LOCATION_SUBLOCATIONS:
          sortOption = LocationSortOption.SUBLOCATIONS;
          break;
        default:
          break;
      }

      let sortOrder: PageSortOrder | undefined;
      switch (changedColumn.getSort()) {
        case 'asc':
          sortOrder = PageSortOrder.ASC;
          break;
        case 'desc':
          sortOrder = PageSortOrder.DESC;
          break;
        default:
          sortOption = undefined;
          break;
      }

      setFiltersToUrl({
        ...filters.current,
        sortOption,
        sortDirection: sortOrder,
      });
    }
  }

  return (
    <TabbedPageLayout>
      <TabbedPageLayoutBody>
        <div className="flex h-full flex-grow flex-col">
          {isError ? (
            <ErrorLoadingDataAlert />
          ) : (
            <>
              <div className="mb-2 flex justify-between items-center">
                <FilterBar onSearch={handleSearch} initialFilterValues={filters.current} isLoading={isLoading}>
                  <div className="w-44">
                    <TextFilter label={t('search')} />
                  </div>
                </FilterBar>

                <div>
                  <LocationHierarchyToggleButton displayMode={displayMode} reverseOrder onChange={onLocationDisplayChanged} />
                </div>
              </div>
              {displayMode === DisplayMode.HIERARCHICAL ? (
                <HierarchicalLocationsGrid
                  data={data}
                  isLoading={isLoading}
                  onSelectionChanged={setSelectedRows}
                  onRowDoubleClicked={(locationId) => navigate(`../${locationId}`)}
                  hasPermission={hasPermission(Permission.LOCATIONS_EDIT)}
                  initialSortOption={hierarchicalSortOption}
                  initialSortOrder={hierarchicalSortOrder}
                  onSortChanged={onHierarchicalSortOptionChanged}
                />
              ) : (
                <IndividualLocationsGrid
                  data={data}
                  isLoading={isLoading}
                  onSelectionChanged={setSelectedRows}
                  onRowDoubleClicked={(locationId) => navigate(`../${locationId}`)}
                  hasPermission={hasPermission(Permission.LOCATIONS_EDIT)}
                  initialSortOption={individualSortOption}
                  initialSortOrder={individualSortOrder}
                  onSortChanged={onIndividualSortOptionChanged}
                />
              )}
            </>
          )}
        </div>

        <ActionBar visible={hasSelectedRows}>
          <div className="flex items-center  ">
            <Tooltip title="Clear Selection">
              <Checkbox checked={true} name="deselectCheckbox" color="secondary" onClick={onDeselectAllClicked} />
            </Tooltip>
            <div className="mr-16 font-semibold">
              {selectedRows.length} {t('location', { count: selectedRows.length })}
            </div>
            <div>
              <Button variant="contained" onClick={onSetLabelsClicked}>
                {t('set_labels')}
              </Button>
            </div>
          </div>
        </ActionBar>

        <ManageLabelAssignmentsDrawer
          isOpen={isManageLabelAssignmentsDrawerOpen}
          onClose={() => {
            setIsManageLabelAssignmentsDrawerOpen(false);
            clearSelections();
            fetchData();
          }}
          entityType={LabelAssignmentEntityType.LOCATION}
          labelAssignments={selectedRows.flatMap(
            (row) =>
              row.dto.labels?.flatMap((label) => {
                return {
                  labelId: label.id,
                  labelValueId: label.value.id,
                  entityType: LabelAssignmentEntityType.LOCATION,
                  entityId: row.dto.id,
                };
              }) ?? [],
          )}
          entityIds={selectedRows.map((row) => row.dto.id)}
        />
      </TabbedPageLayoutBody>
    </TabbedPageLayout>
  );
};
