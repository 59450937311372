import { useState, useCallback } from 'react';
import { FilterFieldName, FilterValues } from './FilterBarContext';

const useFilterBar = ({
  onSearch,
  initialFilterValues = {},
}: { onSearch?: (filterValues: FilterValues) => void; initialFilterValues?: FilterValues } = {}) => {
  const [filterValues, setFilterValues] = useState<FilterValues>(initialFilterValues);

  const setFilterValue = (field: FilterFieldName, value: unknown) => {
    setFilterValues((prevValues) => {
      if (value === undefined || value === null || value === '') {
        const { [field]: _, ...rest } = prevValues;

        return rest;
      } else {
        // If the value is not undefined, null or empty string, we set it
        return {
          ...prevValues,
          [field]: value,
        };
      }
    });
  };

  const triggerSearch = useCallback(() => {
    console.log('Triggering search with filter values:', filterValues);
    if (onSearch) {
      onSearch(filterValues);
    }
  }, [filterValues, onSearch]);

  const isFilterActive = (filterName: FilterFieldName) => {
    const value = filterValues[filterName];

    return !(value === undefined || value === null || value === '');
  };

  return { filterValues, setFilterValue, triggerSearch, isFilterActive };
};

export default useFilterBar;
