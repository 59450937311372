import { ResourceLanguage } from 'i18next';
import { DisplayMode } from '@/types/display-mode.enum';
import { TopAssetTypesMetric } from '@/modules/dashboards/components/widgets/top-assets-widget/TopAssetTypesWidgetConfiguration';
import { TopLocationsMetric } from '@/modules/dashboards/components/widgets/top-locations-widget/TopLocationsWidgetConfiguration';
import { GroupPerTimeType } from '@/modules/dashboards/components/widgets/events-over-time-widget/group-per-time-type.enum';
import { EventSourceType, EventType, GpsSourceType } from '@/modules/events/api/events/event.contracts';

export const enTranslations: ResourceLanguage = {
  translation: {
    applicationLog: {
      title: 'Application Log',
      messageId: 'Message ID',
      logItem_one: 'Log item',
      logItem_other: 'Log items',
      occurredAt: 'Occurred At',
      message: 'Message',

      severity: {
        title: 'Severity',
        error: 'Error',
        warning: 'Warning',
        info: 'Info',
        all: 'All Severities',
      },
      category: {
        title: 'Category',
        import: 'Import',
        eventProcessing: 'Event Processing',
        other: 'Other',
        all: 'All Categories',
      },
    },
    balance_block: {
      title: 'Balances',
      hierarchical: 'Hierarchical',
      individual: 'Individual',
      total_balance: 'Total Balance',
      total_balance_helper_text: 'Balance at this location: {{balance}}, All children: {{children}}',
      incoming: 'Incoming',
      incoming_helper_text: 'Incoming at this location: {{balance}}, All children: {{children}}',
      outgoing: 'Outgoing',
      outgoing_helper_text: 'Outgoing at this location: {{balance}}, All children: {{children}}',
      return_rate: 'Return Rate',
      return_rate_tooltip: '((Outgoing - Outgoing excluded return locations) / Incoming) * 100',
      staytime: 'Staytime',
      staytime_helper_text: 'Stale assets at this location: {{balance}}, All children: {{children}}',
      [DisplayMode.HIERARCHICAL]: 'Hierarchical',
      [DisplayMode.INDIVIDUAL]: 'Individual',
      location_balance_helper_text: "This row shows the summed total of the balance of this location + all of it's {{count}} child locations",
    },
    balances: 'Balances',
    balance: 'Balance',
    hierarchical: 'Hierarchical',
    individual: 'Individual',
    total_balance: 'Total Balance',
    total_balance_helper_text: 'Balance at this location: {{balance}}, All children: {{children}}',
    incoming: 'Incoming',
    incoming_helper_text: 'Incoming at this location: {{balance}}, All children: {{children}}',
    outgoing: 'Outgoing',
    outgoing_helper_text: 'Outgoing at this location: {{balance}}, All children: {{children}}',
    return_rate: 'Return Rate',
    return_rate_tooltip: '((Outgoing - Outgoing excluded return locations) / Incoming) * 100',
    staytime: 'Staytime',
    staytime_helper_text: 'Stale assets at this location: {{balance}}, All children: {{children}}',
    [DisplayMode.HIERARCHICAL]: 'Hierarchical',
    [DisplayMode.INDIVIDUAL]: 'Individual',
    location_balance_helper_text: "This row shows the summed total of the balance of this location + all of it's {{count}} child locations",
    dashboard: {
      top_asset_types_metric: {
        [TopAssetTypesMetric.assetCount]: 'Asset Count',
        [TopAssetTypesMetric.assetTotalMonetaryValue]: 'Asset Total Monetary Value',
      },
      top_locations_metric: {
        [TopLocationsMetric.assetCount]: 'Asset Count',
        [TopLocationsMetric.assetTotalMonetaryValue]: 'Asset Total Monetary Value',
      },
      group_per_time_type: {
        [GroupPerTimeType.DAY]: 'Day',
        [GroupPerTimeType.HOUR]: 'Hour',
        [GroupPerTimeType.WEEK]: 'Week',
      },
      balance_over_time_period: {
        last_30_days: 'Last 30 days',
        last_90_days: 'Last 90 days',
      },
      dashboard: 'Dashboard',
      home: 'Home',
      admin: 'Admin',
      create: {
        title: 'Create a new Dashboard',
        description: 'Enter the name of the Dashboard you would like to create:',
        nameLabel: 'Name',
        helperText: 'At least 3 characters',
        cancel: 'Cancel',
        createButton: 'Create',
        success: 'Dashboard created!',
      },
      rename: {
        title: 'Rename Dashboard',
        description: 'Enter the new name for the Dashboard:',
        newNameLabel: 'New Name',
        helperText: 'At least 3 characters',
        cancel: 'Cancel',
        renameButton: 'Rename',
        success: 'Dashboard renamed!',
      },
      widget: {
        deleteButton: 'Delete Widget',
        editWidget: "Edit '{{widgetName}}' Widget",
        closeAriaLabel: 'close',
        editConfiguration: 'Edit the configuration of this widget below.',
      },
      widgetCatalog: {
        addWidgets: 'Add Widgets',
        closeAriaLabel: 'close',
        dragDropInstruction: 'Drag and drop a widget from the list below onto the dashboard to start using it.',
      },
      balanceOverTimeWidget: {
        noBalancesFound: 'No balances found.',
        title: 'Location Balance Over Time',
        description: 'Shows the Balances of a Location over time, grouped by a specified timespan.',
      },
      balanceOverTimeWidgetConfiguration: {
        title: 'Balance per day',
      },
      countWidget: {
        title: 'Asset Count',
        description: 'Shows the count of Assets based on Location or Label filters.',
      },
      countWidgetConfiguration: {
        title: 'Total Assets',
      },
      eventsOverTimeByTypeWidget: {
        title: 'Events Over Time by Type',
        description: 'Shows the total amount of Events of the last 7 days, grouped by Type.',
      },
      eventsOverTimeByTypeWidgetConfiguration: {
        title: 'Events Over Time by Type',
      },
      eventsOverTimeWidget: {
        title: 'Events Over Time',
        description: 'Shows the total amount of Events, grouped by a specified timespan.',
      },
      eventsOverTimeWidgetConfiguration: {
        title: 'Events per day',
      },
      mapWidget: {
        title: 'Map',
        description: 'Shows the position of your Assets on the map.',
      },
      mapWidgetConfiguration: {
        title: 'Map',
      },
      systemTotalWidget: {
        title: 'Total',
        description: 'Shows the total amount of Assets, Trackers, Events or Locations in the system.',
      },
      systemTotalWidgetConfiguration: {
        title: 'Total',
      },
      topAssetTypesWidget: {
        title: 'Top Asset Types',
        description: 'A Top 10 of Asset Types based on a metric you select.',
      },
      topAssetTypesWidgetConfiguration: {
        title: 'Top Asset Types',
      },
      topLocationsWidget: {
        title: 'Top Locations',
        description: 'A Top 10 of Locations based on a metric you select.',
      },
      topLocationsWidgetConfiguration: {
        title: 'Top Locations by Asset Count',
      },
      deleteConfirmation: {
        questionText: 'Are you sure you want to delete this dashboard?',
        questionTitle: 'Delete Dashboard',
      },
      header: 'Dashboards',
      addTab: '+ Dashboard',
      actions: {
        showFullscreen: 'Show Fullscreen',
        editWidgets: 'Edit Widgets',
        deleteDashboard: 'Delete Dashboard',
        renameDashboard: 'Rename Dashboard',
        saveDashboard: 'Save Dashboard',
      },
    },

    event: {
      title: 'Events',
      all: 'All',
      handheld: 'Handheld',
      event_one: 'Event',
      event_other: 'Events',
      occurred_at: 'Occurred At',
      source: 'Source',
      coordinates_source: 'Coordinates Source',
      coordinates_accuracy: 'Coordinates Accuracy',
      asset_code: 'Asset Code',
      tracker_type: 'Tracker Type',
      location: 'Location',
      tracker: 'Tracker',
      device_name: 'Device Name',
      rfids: 'RFIDs',
      barcodes: 'Barcodes',
      mutations: 'Mutations',
      assets: 'Assets',
      trackers: 'Trackers',

      type: {
        title: 'Type',
        [EventType.Observation]: 'Observation',
        [EventType.Incoming]: 'Incoming',
        [EventType.Outgoing]: 'Outgoing',
        [EventType.Inventory]: 'Inventory',
        [EventType.ADMINISTRATIVE]: 'Administrative',
        undefined: 'Unknown',
        all: 'All Event Types',
      },

      gps_source: {
        [GpsSourceType.GPS]: 'GPS',
        [GpsSourceType.SIGFOX_ATLAS_NATIVE]: 'Sigfox triangulation',
        [GpsSourceType.CERTIFIED_LOCATION_WIFI]: 'Certified WiFi',
        [GpsSourceType.WIFI]: 'General WiFi',
        [GpsSourceType.CERTIFIED_LOCATION_BLE]: 'Certified BLE',
        [GpsSourceType.CELLULAR]: 'Cellular triangulation',
        [GpsSourceType.LOCATION_CODE]: 'Location Code',
        undefined: 'Unknown',
        null: '',
      },

      errors: {
        date_from_after: 'Date from cannot be after date to',
        date_to_before: 'Date to cannot be before date from',
      },
    },
    importer: {
      upload_a_file: 'Upload a File',
      import_error: {
        NON_UNIQUE_ASSET_CODE: 'NON_UNIQUE_ASSET_CODE',
        NON_UNIQUE_TRACKER_BARCODE: 'NON_UNIQUE_TRACKER_BARCODE',
        NON_UNIQUE_TRACKER_EPC: 'NON_UNIQUE_TRACKER_EPC',
        NON_UNIQUE_TRACKER_DEVICE_ID: 'NON_UNIQUE_TRACKER_DEVICE_ID',
        NON_EXISTENT_ASSET_TYPE_CODE: "Asset Type Doesn't Exist",
        BARCODE_AND_EPC_CODE_AND_UNATAG_ID_NOT_DEFINED: 'BARCODE_AND_EPC_CODE_AND_UNATAG_ID_NOT_DEFINED',
        NON_EXISTENT_LOCATION_CODE: "Location Doesn't Exist",
      },
      next: 'Next',
      previous: 'Previous',
      preview: {
        preview: 'Preview',
        file_contains_errors: 'The file contains {{count}} errors that need to be resolved. Once resolved, try uploading the file again.',
        file_upload_failed: 'File upload failed. Please try again.',
      },
      upload: {
        file_details: 'File Details',
        files_you_can_imported: 'Select a CSV file to be imported.',
        file_size: 'Size',
        select_file: 'Select file',
        select_csv_file: 'Select CSV file',
        change_file: 'Change File',
      },
      import: {
        import: 'Import',
      },
      asset_import: {
        title: 'Assets',
        description: 'Import Assets, including their Barcode or EPC code or Unatag Device Id.',
      },
      location_import: {
        title: 'Locations',
        description: 'Import Locations',
      },
      parent_location_import: {
        title: 'Location Parents',
        description: 'Set Location Parents',
      },
    },
    assets_map: 'Assets Map',
    legend: 'Legend',
    in_transit: 'In Transit',
    on_location: 'On Location',
    setting: {
      api: {
        label: 'API',
        title: 'API Settings',
        general_description: 'Common API settings',
        url_title: 'API URL',
        url_description: 'The Public Posito API URL you can use for integrations.',
        documentation_title: 'API Documentation',
        documentation_description: 'The Open API Documentation of the API.',
        keys_title: 'API Keys',
        keys_description: 'Create and manage the API keys that allow acces to the Public API.',
        manage_keys_title: 'Manage API Keys',
        manage_keys_description: 'You can create, rename, enable/disable and remove API Keys below.',
        add: 'Add API Key',
        add_title: 'Add API Key',
        add_name_label: 'Name',
        add_description: 'Enter a name for the new API Key',
        add_success: 'API Key added successfully',
        add_name_validation: 'Name must be between 1 and 25 characters',
        update_success: 'API Key updated successfully',
        delete: 'Delete API Key',
        delete_confirmation: 'Are you sure you want to delete this API Key?',
        delete_success: 'API Key deleted successfully',
        name: 'Name',
        created: 'Created',
        key: 'Key',
        active: 'Active',
      },
      connector: {
        title: 'Connector Settings',
        unaconnect_title: 'UnaConnect',
        unaconnect_description: 'A Portal that can send Tracker Events to Posito.',
        unaconnect_url_title: 'UnaTag Webhook URL',
        unaconnect_url_description: 'The URL to send UnaTag events to. Configure this in UnaConnect.',
        nedap_title: 'Nedap Harmony',
        nedap_description: 'A Portal that can send Tracker Events to Posito.',
        nedap_url_title: 'Nedap Harmony Webhook URL',
        nedap_url_description: 'The URL to send Nedap Harmony events to. Configure this in a Nedap Harmony Hook.',
        epc_title: 'EPC Events',
        epc_description: 'An endpoint to send EPC events towards.',
        epc_url_title: 'EPC Webhook URL',
        epc_url_description: 'The URL to send EPC events to. Configure this in your custom software.',
      },
      event: {
        title: 'Event Settings',
        creation_title: 'Event Creation',
        creation_description: 'Configure how Events are created.',
        auto_create_trackers_title: 'Automatically create Trackers',
        auto_create_trackers_description:
          'When a Tracker is observed (scanned/manual input) which doesn’t match any identifier of the current Trackers in the system, a new Tracker with the observed identifier will be created.',
        update_success: 'Updated setting: Automatically create Trackers',
      },
    },
    trackers: 'Trackers',
    tracker_add: 'Add Tracker',
    tracker_new: 'New Tracker',
    tracker_device_identifier: 'Device Identifier',
    tracker_unique_identifier: 'Unique Identifier',
    tracker: 'Tracker',
    trackers_one: 'Tracker',
    trackers_other: 'Trackers',
    tracker_unknown: 'Unknown Tracker',
    tracker_created: 'Tracker created',
    tracker_updated: 'Tracker updated',
    tracker_deleted: 'Tracker deleted',
    tracker_delete_confirm: 'Are you sure you want to delete this tracker?',
    error_creating_tracker: 'Error Creating Tracker',
    error_updating_tracker: 'Error Updating Tracker',
    error_deleting_tracker: 'Error Deleting Tracker',
    error_duplicate_code: 'The code is already in use, and must be unique. Please provide a different one.',
    error_no_permission_to_edit_trackers: 'You do not have permission to edit trackers',
    tracker_in_use_barcode: 'The Barcode is already in use, and must be unique. Please provide a different one.',
    tracker_in_use_epc: 'The Epc is already in use, and must be unique. Please provide a different one.',
    tracker_in_use_device_id: 'The Device ID is already in use, and must be unique. Please provide a different one.',
    tracker_linked_to_other_asset:
      'This Tracker is currently linked to: Asset with code {{assetCode}}. Saving this Asset will unlink it from Asset with code {{assetCode}} and link it to this Asset.',
    no_trackers_added: 'No Trackers added yet.',
    barcode: 'Barcode',
    rfid: 'RFID',
    unatag: 'UnaTag',
    ble: 'BLE',
    assets: 'Assets',
    sign_in: 'Sign In',
    search: 'Search',
    filter: 'Filter',
    filters: 'Filters',
    unlink: 'Unlink',
    delete: 'Delete',
    save: 'Save',
    no_data: 'No data',
    image_upload: 'Upload Image',
    image_upload_new: 'Upload New Image',
    image_remove: 'Remove Image',
    showing_first: 'Showing first {{amount}}',
    details: 'Details',
    unknown: 'Unknown',
    at: 'at',
    total: 'Total',
    totals: 'Totals',
    code: 'Code',
    type: 'Type',
    identifier: 'Identifier',
    name: 'Name',
    overview: 'Overview',
    export_excel: 'Export to Excel',
    refresh: 'Refresh',
    select_all: 'Select All',
    deselect_all: 'Deselect All',
    occurred_at: 'Occurred At',
    last_seen: 'Last seen',
    general: 'General',
    error_loading_data: 'Error loading data',
    error_loading_data_verbose: 'There was an error loading the data.',
    error_occurred: 'An error occurred',
    cancel: 'Cancel',
    copied: 'Copied!',
    or: 'Or',
    events: 'Events',
    asset_type: 'Asset Type',
    create: 'Create',
    clear_selection: 'Clear Selection',
    toggle_dark_mode: 'Toggle Dark Mode',
    dark_mode: 'Dark Mode',
    configuration: 'Configuration',
    insights: 'Insights',
    dashboards: 'Dashboards',
    map: 'Map',
    operations: 'Operations',
    setup: 'Setup',
    locations: 'Locations',
    scan_a_barcode: 'Scan a Barcode',
    position_the_barcode_in_the_center_of_the_screen: 'Position the barcode in the center of the screen.',
    media_devices_not_supported: 'Media Devices are not supported by this browser.',
    barcode_detector_not_supported: 'Barcode Detector is not supported by this browser.',
    create_new_or_use_existing: 'Create New or Use Existing',
    create_new: 'Create New',
    use_existing: 'Use Existing',
    link: 'Link',
    link_a_tracker_to_an_asset: 'Link a Tracker to an Asset',
    select_asset_type_and_create_new_or_connect_existing:
      'Select an Asset Type and either create a new Asset and/or Tracker, or connect existing ones.',
    asset_code: 'Asset Code',
    existing_trackers: 'Existing Trackers',
    no_existing_trackers: 'No existing trackers',
    add_trackers: 'Add Trackers',
    asset_not_found: 'Asset not found',
    error_fetching_existing_asset: 'Error fetching existing Asset',
    no_asset_selected: 'No Asset selected',
    no_asset_type_selected: 'No Asset Type selected',
    no_asset_code_entered: 'No Asset Code entered',
    no_tracker_identifier_entered: 'No Tracker Identifier entered',
    error_fetching_existing_trackers: "Error linking Asset to Tracker, couldn't fetch existing Trackers",
    barcode_tracker_already_linked: 'Barcode Tracker is already linked to an Asset, unlink it first in the Desktop site',
    rfid_tracker_already_linked: 'RFID Tracker is already linked to an Asset, unlink it first in the Desktop site',
    unatag_tracker_already_linked: 'UnaTag Tracker is already linked to an Asset, unlink it first in the Desktop site',
    ble_tracker_already_linked: 'BLE Tracker is already linked to an Asset, unlink it first in the Desktop site',
    asset_code_already_exists: 'An asset with this code already exists',
    error_fetching_existing_assets: "Error linking Asset to Tracker, couldn't fetch existing Assets",
    error_creating_barcode_tracker: "Error linking Asset to Tracker, couldn't create new Barcode Tracker",
    error_creating_rfid_tracker: "Error linking Asset to Tracker, couldn't create new RFID Tracker",
    error_creating_unatag_tracker: "Error linking Asset to Tracker, couldn't create new UnaTag Tracker",
    error_creating_ble_tracker: "Error linking Asset to Tracker, couldn't create new BLE Tracker",
    no_trackers_created: 'Error linking Asset to Tracker, no Trackers could be created',
    error_creating_new_asset: 'Error linking Asset to Tracker, could not create new Asset',
    error_linking_tracker_to_asset: 'Error linking Asset to Tracker, could not link Tracker to Asset',
    error_linking_asset_to_tracker: 'Error linking Asset to Tracker',
    asset_linked_to_trackers: 'Asset linked to Tracker(s)',
    showing_most_recent_events: 'Showing the {{count}} most recent Events.',
    first_event_date: 'First Event Date',
    last_event_date: 'Last Event Date',
    stay_time: 'Stay Time',
    tracker_n: 'Tracker {{number}}',
    no_gps_location_found: 'No GPS Location found.',
    unarchive_asset_title: 'Unarchive Asset',
    unarchive_asset_text:
      'The asset code you are trying to edit is already used in an archived asset, do you want to unarchive the asset instead?',
    unarchive_asset_type_title: 'Unarchive Asset Type',
    unarchive_asset_type_text:
      'The asset type code you are trying to edit is already used in an archived asset type, do you want to unarchive the asset type instead?',
    asset_type_with_code_already_exists: 'An asset type with this code already exists',

    unarchive_location_title: 'Unarchive Location',
    unarchive_location_text:
      'The location code you are trying to edit is already used in an archived location, do you want to unarchive the location instead?',
    location_with_code_already_exists: 'A location with this code already exists',
    unarchive: 'Unarchive',
    current_location: 'Current Location',
    link_a_tracker: 'Link a Tracker',
    create_new_tracker: 'Create new Tracker',
    delete_confirmation_question:
      "Are you sure you would like to delete this Asset? The Trackers linked to this Asset will not be deleted, but will be unlinked from this Asset. This Asset will be moved to the 'Archived' system location.",
    error_deleting_asset: 'Error deleting Asset',
    asset_deleted: 'Asset deleted',
    no_permission_edit_assets: 'You do not have permission to edit assets',
    duplicate_code: 'The code is already in use, and must be unique. Please provide a different one.',
    error_creating_asset: 'Error creating Asset',
    error_updating_asset: 'Error updating Asset',
    asset_created: 'Asset created',
    asset_updated: 'Asset updated',
    linking_trackers_warning:
      'The Asset was created, but something went wrong when linking the Trackers. Please check the Asset to see if the correct Trackers are linked.',
    new_asset: 'New Asset',
    asset_with_code: 'Asset - {{code}}',
    history: 'History',
    tablist_aria_label: 'Asset Detail Tabs',
    minimum_stay_time_days: 'Minimum Stay Time (days)',
    move_assets: 'Move Assets',
    assets_moved_successfully: 'Assets moved successfully',
    failed_to_move_assets: 'Failed to move assets',
    error_moving_assets: 'Error moving assets',
    move_assets_to_location_title: 'Move {{count}} Asset(s) to Location...',
    move: 'Move', // Ensure this exists under 'asset' or general 'translation'
    no_permission_to_edit_users: 'You do not have permission to edit users',
    error_creating_user: 'Error Creating User',
    user_created: 'User created',
    error_updating_user: 'Error Updating User',
    user_updated: 'User updated',
    no_data_error: 'No Data Error',
    error_deleting_user: 'Error deleting User',
    user_deleted: 'User deleted',
    email: 'Email',
    password: 'Password',
    password_helper_text: 'Enter a Password to change it, leave blank for no change.',
    confirm_delete_user: 'Are you sure you would like to delete this User?',
    this_field_has_to_be_filled: 'This field has to be filled.',
    this_is_not_a_valid_email: 'This is not a valid email.',
    you_must_select_a_role: 'You must select a role.',
    password_with_minimum_characters_required: 'A password with at least 6 characters is required',
    user_avatar: 'Avatar of {{name}}',
    active: 'Active',
    edit: 'Edit',
    users: 'Users',
    roles: 'Roles',
    new_user: 'New User',
    user_name: 'User - {{name}}',
    user_tabs: 'User Tabs',
    add_user: 'Add User',
    view_dashboards: 'View Dashboards',
    allows_user_to_view_dashboards: 'Allows a User to view Dashboards.',
    edit_dashboards: 'Edit Dashboards',
    allows_user_to_create_edit_delete_dashboards: 'Allows a User to create, edit and delete Dashboards.',
    view_map: 'View Map',
    allows_user_to_view_map: 'Allows a User to view Map.',
    view_balances: 'View Balances',
    allows_user_to_view_balances: 'Allows a User to view Balances.',
    view_events: 'View Events',
    allows_user_to_view_events: 'Allows a User to view Events.',
    view_locations: 'View Locations',
    allows_user_to_view_locations: 'Allows a User to view Locations.',
    edit_locations: 'Edit Locations',
    allows_user_to_create_edit_delete_locations: 'Allows a User to create, edit and delete Locations.',
    view_assets: 'View Assets',
    allows_user_to_view_assets: 'Allows a User to view Assets.',
    edit_assets: 'Edit Assets',
    allows_user_to_create_edit_delete_assets: 'Allows a User to create, edit and delete Assets.',
    asset_types: 'Asset Types',
    view_asset_types: 'View Asset Types',
    allows_user_to_view_asset_types: 'Allows a User to view Asset Types.',
    edit_asset_types: 'Edit Asset Types',
    allows_user_to_create_edit_delete_asset_types: 'Allows a User to create, edit and delete Asset Types.',
    view_trackers: 'View Trackers',
    allows_user_to_view_trackers: 'Allows a User to view Trackers.',
    edit_trackers: 'Edit Trackers',
    allows_user_to_create_edit_delete_trackers: 'Allows a User to create, edit and delete Trackers.',
    devices: 'Devices',
    view_devices: 'View Devices',
    allows_user_to_view_devices: 'Allows a User to view Devices.',
    edit_devices: 'Edit Devices',
    allows_user_to_create_edit_delete_devices: 'Allows a User to create, edit and delete Devices.',
    labels: 'Labels',
    view_labels: 'View Labels',
    allows_user_to_view_labels: 'Allows a User to view Labels.',
    edit_labels: 'Edit Labels',
    allows_user_to_create_edit_delete_labels: 'Allows a User to create, edit and delete Labels.',
    event_settings: 'Event Settings',
    view_event_settings: 'View Event Settings',
    allows_user_to_view_event_settings: 'Allows a User to view Event Settings.',
    edit_event_settings: 'Edit Event Settings',
    allows_user_to_create_edit_delete_event_settings: 'Allows a User to create, edit and delete Event Settings.',
    connectors: 'Connectors',
    view_connectors: 'View Connectors',
    allows_user_to_view_connectors: 'Allows a User to view Connectors.',
    api_settings: 'API Settings',
    view_api_settings: 'View API Settings',
    allows_user_to_view_api_settings: 'Allows a User to view API Settings.',
    edit_api_settings: 'Edit API Settings',
    allows_user_to_edit_api_settings: 'Allows a User to edit API Settings.',
    view_users: 'View Users',
    allows_user_to_view_users: 'Allows a User to view Users.',
    edit_users: 'Edit Users',
    allows_user_to_edit_users: 'Allows a User to edit Users.',
    view_roles: 'View Roles',
    allows_user_to_view_roles: 'Allows a User to view Roles.',
    edit_roles: 'Edit Roles',
    allows_user_to_create_edit_delete_roles: 'Allows a User to create, edit and delete Roles.',
    file_importer: 'File Importer',
    use_file_importer: 'Use File Importer',
    allows_user_to_use_file_importer: 'Allows a User to use the File Importer.',
    application_log: 'Application Log',
    view_application_log: 'View Application Log',
    allows_user_to_view_application_log: 'Allows a User to view the Application Log.',
    mobile: 'Mobile',
    mobile_connect: 'Mobile Connect',
    allows_user_to_connect_trackers_to_assets_in_smartphone_view:
      'Allows a User to connect Trackers to Assets in the smartphone view of Posito.',
    mobile_events: 'Mobile Events',
    allows_user_to_view_events_in_smartphone_view: 'Allows a User to view Events in the smartphone view of Posito.',
    actions: 'Actions',
    read_only_role_cannot_be_edited: 'This Role is a Read-Only Role and cannot be edited, to prevent locking yourself out of the system.',
    role: 'Role',
    base_data: 'Base Data',
    settings: 'Settings',
    api: 'API',
    updated_setting: 'Updated setting',
    error_updating_setting: 'Error updating setting',
    updated_setting_auto_create_trackers: "Updated setting: 'Automatically create Trackers'",
    download_template: 'Download Template',
    start_import: 'Start Import',
    import_ready_message: 'You are now ready to import the data into Posito. Click the button below to start the import process.',
    importing_data: 'Importing data...',
    import_successful: 'Import successful',
    import_failed: 'Import failed',
    uploading_file: 'Uploading file...',
    import: 'Import',
    asset_import: 'Asset Import',
    import_assets_and_associate_them_with_trackers: 'Import assets and associate them with trackers.',
    assets_to_create: 'Assets to Create',
    trackers_to_create: 'Trackers to Create',
    valid: 'Valid',
    invalid: 'Invalid',
    error_reason: 'Error Reason',
    missing_asset_code: 'Missing asset code',
    invalid_asset_type: 'Invalid asset type',
    unknown_error: 'Unknown error',
    non_unique_asset_code: 'NON_UNIQUE_ASSET_CODE',
    non_unique_tracker_barcode: 'NON_UNIQUE_TRACKER_BARCODE',
    non_unique_tracker_epc: 'NON_UNIQUE_TRACKER_EPC',
    non_unique_tracker_device_id: 'NON_UNIQUE_TRACKER_DEVICE_ID',
    non_existent_asset_type_code: "Asset Type Doesn't Exist",
    barcode_and_epc_code_and_unatag_id_not_defined: 'BARCODE_AND_EPC_CODE_AND_UNATAG_ID_NOT_DEFINED',
    non_existent_location_code: "Location Doesn't Exist",
    location_import: 'Location Import',
    import_locations_into_the_system: 'Import locations into the system.',
    locations_to_create: 'Locations to Create',
    location_code: 'Location Code',
    location_name: 'Location Name',
    missing_location_code: 'Missing location code',
    missing_location_name: 'Missing location name',
    duplicate_location_code: 'Duplicate location code',
    parent_location_import: 'Parent Location Import',
    import_parent_locations: 'Import parent locations.',
    number_locations_to_assign_new_parent: 'Number Locations to assign (new) Parent',
    parent_location_code: 'Parent Location Code',
    child_location_code: 'Child Location Code',
    missing_parent_location_code: 'Missing parent location code',
    missing_child_location_code: 'Missing child location code',
    duplicate_parent_location_code: 'Duplicate parent location code',
    no_events_found: 'No events found',
    for: 'For',
    asset: 'Asset',
    asset_one: 'Asset',
    asset_other: 'Assets',
    move_assets_to_another_type: 'Move Assets to Another Type',
    asset_type_cannot_be_deleted:
      'The Asset Type "{{assetTypeName}}" cannot be deleted because it has associated Assets. Please select another Asset Type to move these Assets to.',
    select_new_asset_type: 'Select New Asset Type',
    // Failed to move assets. Please try again.
    failed_to_move_assets_try_again: 'Failed to move Assets. Please try again, or contact support if the issue persists.',
    add_asset_type: 'Add Asset Type',
    image: 'Image',
    description: 'Description',
    model_year: 'Model Year',
    width_mm: 'Width (mm)',
    height_mm: 'Height (mm)',
    depth_mm: 'Depth (mm)',
    volume_cubic_mm: 'Volume (cubic mm)',
    monetary_value: 'Monetary Value',
    number_of_assets: '# of Assets',
    new_asset_type: 'New Asset Type',
    current_balance: 'Current Balance',
    error_creating_asset_type: 'Error creating asset type',
    error_updating_asset_type: 'Error updating asset type',
    error_uploading_image: 'Error uploading image',
    error_removing_image: 'Error removing image',
    asset_type_created: 'Asset type created',
    asset_type_updated: 'Asset type updated',
    no_permission_edit_asset_types: 'You do not have permission to edit asset types',
    asset_type_deleted: 'Asset type deleted',
    delete_asset_type_confirmation: 'Are you sure you want to delete this asset type?',
    error_asset_type_has_assets:
      'This asset type has assets and cannot be deleted. Please assign a different asset type to the current assets.',
    error_deleting_asset_type: 'Error deleting asset type',
    asset_types_helper_text: 'Select one or more asset types.',
    add: 'Add',
    asset_type_code: 'Asset Type Code',
    asset_type_name: 'Asset Type Name',
    from_location: 'From Location',
    to_location: 'To Location',
    movement: 'Movement',
    movements: 'Movements',
    movement_one: 'Movement',
    movement_other: 'Movements',
    event_type: 'Event Type',
    event_source: 'Event Source',
    location: 'Location',
    assets_on_location: 'Assets on this Location',
    recent_events: 'Recent Events',
    mobile_scanner: 'Mobile Scanner',
    ble_gateway: 'BLE Gateway',
    all: 'All',
    mobile_scanners: 'Mobile Scanners',
    ble_gateways: 'BLE Gateways',
    no_edit_device_permission: 'You do not have permission to edit devices',
    device_created: 'Device created',
    device_updated: 'Device updated',
    error_creating_device: 'Error creating device',
    error_updating_device: 'Error updating device',
    device: 'Device',
    new_device: 'New Device',
    brand: 'Brand',
    serial_number: 'Serial Number',
    add_device: 'Add Device',
    last_event_received_at: 'Last Event Received At',
    code_can_be_used_in_posito_scan_app: 'This code can be used in the Posito Scan app to link the app to this Device.',
    battery_voltage: 'Battery Voltage',
    battery_voltage_updated_at: 'Battery voltage last updated at {{date}}',
    tracker_type: 'Tracker Type',
    linked_trackers: 'Linked',
    unlinked_trackers: 'Unlinked',
    state: 'State',
    sublocations: 'Sublocations',
    geofence: 'Geofence',
    geofence_select_title: 'Select Geofence Address',
    geofence_select_description:
      'These are the locations we could find based on the adress of this location. Select one to determine the location of the geofence. Optionally you can adjust the geofence radius.',
    geofence_radius: 'Geofence radius',
    location_select: 'Select a location',
    location_select_description: 'Select a location to filter on.',
    balance_over_time_for: "Balance over time for '{{locationName}}'",
    period: 'Period',
    select_up_to_5_asset_types: 'Select up to 5 Asset Types to display',
    delete_location_error_assets: 'Location has assets assigned. Move them to a different location before deleting this location.',
    delete_location_error_system: 'This is a system location, and system locations cannot be deleted.',
    delete_location_error_children:
      'Location has children. Delete or re-assign them to a different parent location, before deleting this location.',
    delete_location_error: 'Error deleting location',
    delete_location_success: 'Location deleted',
    location_code_already_exists: 'The code is already in use, and must be unique. Please provide a different one.',
    create_location_error: 'Error creating location',
    create_location_success: 'Location created',
    update_location_error: 'Error updating location',
    update_location_success: 'Location updated',
    delete_location_confirmation:
      'Are you sure you would like to delete this location? The events and movements linked to this location will not be deleted, to retain a complete history of your assets.',
    location_no_permission: 'You do not have permission to edit locations',
    parent_location: 'Parent Location',
    include_in_return_rate: 'Include in return rate calculation',
    include_in_return_rate_tooltip:
      'Decides whether assets arriving at this location will be included in the return rate of the location where the asset departed from',
    address: 'Address',
    country: 'Country',
    city: 'City',
    postal_code: 'Postal Code',
    street_name: 'Street Name',
    street_number: 'Street Number',
    new_location: 'New Location',
    system_location_alert: 'This location is a system location and it cannot be deleted nor can the code be changed.',
    system_location_tooltip:
      ' System Locations are created by Posito and are used for certain predefined functionalities. Posito uses the location code to identify a system location and thus cannot be changed.',
    more_info: 'More Info',
    location_one: 'Location',
    location_other: 'Locations',
    set_labels: 'Set Labels',
    discard_changes: 'Discard changes',
    label_fetch_error: 'There was an error fetching the labels. Please try again later.',
    add_label: 'Add Label',
    select_label_value: 'Please select a value for the label',
    select_label: 'Please select a Label',
    manage_label_assignments: 'Manage Label Assignments',
    manage_label_assignments_empty_state_title: 'No labels found',
    create_label: 'Create Label',
    no_labels_assigned: 'No labels assigned to selection.',
    label_create_description: 'You can create and manage labels in the configuration section.',
    labels_description: 'Labels help you to group and categorize your locations.',
    create_a_label: 'Create a Label',
    assign_label_to_location: 'and start assigning it to your locations.',
    label_description_example: 'Labels help organize your resources',
    assignments: 'Assignments',
    value: 'Value',
    values: 'Values',
    label_one: 'Label',
    label_other: 'Labels',
    new_label: 'New Label',
    label_created: 'Label created',
    label_updated: 'Label updated',
    label_deleted: 'Label deleted',
    label_name: 'Label Name',
    color: 'Color',
    add_value: 'Add Value',
    label_value_caption: 'Add one or more Label values that can help categorize Locations.',
    label_delete_confirmation: 'Are you sure you would like to delete this label?',
    label_values: 'Label Values',
    label_helper_text: 'Select one or more label values.',
    label_error_permission: 'You do not have permission to edit labels',
    label_error_duplicate: 'The name is already in use, and must be unique. Please provide a different one.',
    label_error_creating: 'Error creating Label',
    label_error_updating: 'Error updating Label',
    label_error_deleting: 'Error deleting Label',
    label_error_values_unique: 'Values must be unique',
    label_error_values_empty: 'Values cannot be empty',
    assets_total_elements: 'Assets ({{count}})',
    arrived: 'Arrived',
    pagination_result: '{{total}} {{entity}} found',
    balance_per_asset_type: 'Balance per Asset Type',
    arrived_at: 'Arrived at',
    export_to_excel: 'Export to Excel',
    close: 'Close',
    error_loading_assets: 'Error loading assets',
    assets_at_location: 'Assets at location',
    model: 'Model',
    enums: {
      event_source: {
        [EventSourceType.GATE]: 'Gate',
        [EventSourceType.MANUAL]: 'Manual',
        [EventSourceType.TRACKER]: 'Tracker',
        [EventSourceType.IMPORTER]: 'Importer',
        [EventSourceType.HANDHELD]: 'Handheld',
        [EventSourceType.BLE_GATEWAY]: 'BLE Gateway',
        undefined: 'Unknown',
      } as Record<EventSourceType, string>,
    },
    high: 'High',
    medium: 'Medium',
    low: 'Low',
    location_entered_at: 'Location entered at {{date}}',
    number_of_days: '{{day}} days',
    no_permission_to_view_page: 'You do not have permission to view this page',
    week_short: 'Wk {{week}}',
    error_loading_settings: 'Error loading settings',
    error_loading_user: 'Error loading user',
    error_loading_user_settings: 'Error loading user settings',
    profile: 'Profile',
    environment: 'Environment',
    development: 'Development',
    production: 'Production',
    logout: 'Log Out',
    language: 'Language',
    group_balance_over_time: 'Group balances over time',
    next: 'Next',
    previous: 'Previous',
    count: 'Count',
    no_locations_found: 'No locations found',
    no_asset_types_found: 'No asset types found',
    date_to: 'Date to',
    date_from: 'Date from',
    select_a_tracker: 'Select a Tracker',
  },
};
