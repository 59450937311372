import { FunctionComponent, useContext } from 'react';
import { FilterBarContext } from './FilterBarContext';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from '@/lib';
import { cn } from '@/lib/utils/utils';

interface FilterBarSearchButtonProps {
  isLoading?: boolean;
  className?: string;
  size?: 'small' | 'medium';
}

const FilterBarSearchButton: FunctionComponent<FilterBarSearchButtonProps> = ({ isLoading, className, size }) => {
  const { triggerSearch } = useContext(FilterBarContext);
  const { t } = useTranslation();

  return (
    <LoadingButton loading={isLoading} variant="contained" color="primary" size={size} onClick={triggerSearch} className={cn(className)}>
      {t('search')}
    </LoadingButton>
  );
};

export default FilterBarSearchButton;
