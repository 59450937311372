import { TabbedPageLayoutBody } from '@/modules/application/components/TabbedPageLayoutBody';
import { TabbedPageLayout } from '@/modules/application/layouts/TabbedPageLayout';
import { FunctionComponent, useState } from 'react';
import { LocationsBalanceGrid } from '../components/LocationsBalanceGrid';
import { useApiCall } from '@/hooks/useApiCall';
import { balanceService } from '../api/balances/balance.service';
import { BalancesAtLocationModel } from '../models/BalanceAtLocationModel';
import FilterBar from '@/components/filterbar/FilterBar';
import { FilterFieldName, FilterValues } from '@/components/filterbar/FilterBarContext';
import FilterBarSearchButton from '@/components/filterbar/FilterBarSearchButton';
import LocationFilter from '@/components/filterbar/filters/LocationFilter';
import dayjs from 'dayjs';
import LabelValuesFilter from '@/components/filterbar/filters/LabelValuesFilter';
import LocationHierarchyToggleButton from '@/modules/locations/components/LocationHierarchyToggleButton';
import { LocationsBalanceisHierarchicalGrid } from '../components/LocationsBalanceisHierarchicalGrid';
import TextFilter from '@/components/filterbar/filters/TextFilter';
import { DisplayMode } from '@/types/display-mode.enum';
import { useTranslation } from '@/lib';
import { useUserSettings } from '@/modules/users/contexts/UserSettingsContext';
import { UserSettingKey } from '@/modules/users/api/user-settings/user-setting.contracts';
import { useSearchParams } from 'react-router-dom';
import useQueryParamsFilters from '@/hooks/useQueryParamFilters';
import { CacheKey } from '@/providers/cache-provider/cache-key.enum';
import { PageSortOption, PageSortOrder } from '@/lib/api/pagination.page.dto';
import { BalanceSortOption } from '../api/balances/balance.contracts';
import { SortChangedEvent } from 'ag-grid-community';
import { ColumnID } from '@/components/grid/column-ids';

interface BalanceOverviewPageProps {}

const BalanceOverviewPage: FunctionComponent<BalanceOverviewPageProps> = () => {
  const { getUserSettingValueByKey, setUserSettingByKey } = useUserSettings();
  const { t } = useTranslation();

  const [displayMode, setDisplayMode] = useState<DisplayMode>(
    getUserSettingValueByKey(UserSettingKey.BALANCE_OVERVIEW_DISPLAY_MODE) ?? DisplayMode.HIERARCHICAL,
  );
  const [searchParams] = useSearchParams();

  const { filters, setFiltersToUrl } = useQueryParamsFilters(
    {
      searchText: searchParams.get('searchText') ?? '',
      sortDirection: searchParams.get('sortDirection') as PageSortOrder,
      sortOption: searchParams.get('sortOption') as PageSortOption,
    },
    CacheKey.BALANCE_FILTERS,
  );

  const [hierarchicalSortOption, setHierarchicalSortOption] = useState<PageSortOption>(
    filters.current.sortOption ?? BalanceSortOption.TOTAL_BALANCE,
  );
  const [hierarchicalSortOrder, setHierarchicalSortOrder] = useState<PageSortOrder>(filters.current.sortDirection ?? PageSortOrder.DESC);
  const [individualSortOption, setIndividualSortOption] = useState<PageSortOption>(
    filters.current.sortOption ?? BalanceSortOption.TOTAL_BALANCE,
  );
  const [individualSortOrder, setIndividualSortOrder] = useState<PageSortOrder>(filters.current.sortDirection ?? PageSortOrder.DESC);

  const { data, isError, isLoading, setApiCallArg } = useApiCall<BalancesAtLocationModel[]>(() => {
    if (displayMode === DisplayMode.HIERARCHICAL) {
      return balanceService.getBalancesForLocationsHierarchical();
    } else {
      return balanceService.getBalancesForLocations();
    }
  });

  function onFilterChange(filterValues: FilterValues) {
    setFiltersToUrl(filterValues);
  }

  const handleSearch = (filterValues: FilterValues) => {
    setFiltersToUrl(filterValues);
    searchWithCurrentFilterValues(displayMode, filterValues);
  };

  function onBalancenDisplayChanged(displayMode: DisplayMode) {
    setUserSettingByKey(UserSettingKey.BALANCE_OVERVIEW_DISPLAY_MODE, displayMode, false);
    setDisplayMode(displayMode);
    searchWithCurrentFilterValues(displayMode, filters.current);
  }

  function searchWithCurrentFilterValues(displayMode: DisplayMode, filterValues: FilterValues) {
    if (!filterValues) {
      return;
    }

    // clearSelections();

    if (displayMode === DisplayMode.HIERARCHICAL) {
      setApiCallArg(() =>
        balanceService.getBalancesForLocationsHierarchical({
          query: filterValues.searchText ?? '',
          labelValueIds: filterValues.labelValueIds,
          locationId: filterValues.locationId,
        }),
      );
    } else {
      setApiCallArg(() =>
        balanceService.getBalancesForLocations({
          query: filterValues.searchText ?? '',
          labelValueIds: filterValues.labelValueIds,
          locationId: filterValues.locationId,
        }),
      );
    }
  }

  function onSortOptionChanged(event: SortChangedEvent<BalancesAtLocationModel>) {
    if (event.columns && event.columns.length > 0 && event.source === 'uiColumnSorted') {
      const changedColumn = event.columns[0];
      const changedColumnId = changedColumn.getColId();

      let sortOption: BalanceSortOption | string | undefined;
      switch (changedColumnId) {
        case ColumnID.LOCATION_NAME:
          sortOption = BalanceSortOption.LOCATION_NAME;
          break;
        case ColumnID.TOTAL_BALANCE:
          sortOption = BalanceSortOption.TOTAL_BALANCE;
          break;
        case ColumnID.TOTAL_INCOMING:
          sortOption = BalanceSortOption.INCOMING;
          break;
        case ColumnID.TOTAL_OUTGOING:
          sortOption = BalanceSortOption.OUTGOING;
          break;
        case ColumnID.RETURN_RATE:
          sortOption = BalanceSortOption.RETURN_RATE;
          break;
        case ColumnID.STAYTIME:
          sortOption = BalanceSortOption.STAYTIME_30;
          break;
        case ColumnID.STAYTIME_60_DAYS:
          sortOption = BalanceSortOption.STAYTIME_60;
          break;
        default:
          sortOption = changedColumnId; // Should only be used for the dynamic column ids
          break;
      }

      let sortOrder: PageSortOrder | undefined;
      switch (changedColumn.getSort()) {
        case 'asc':
          sortOrder = PageSortOrder.ASC;
          break;
        case 'desc':
          sortOrder = PageSortOrder.DESC;
          break;
        default:
          sortOption = undefined;
          break;
      }

      setFiltersToUrl({
        ...filters.current,
        sortOption,
        sortDirection: sortOrder,
      });
    }
  }
  const filterComponents = [
    { label: t('search'), filterName: FilterFieldName.searchText, filter: <TextFilter label={t('search')} /> },
    { label: t('location'), filterName: FilterFieldName.locationId, filter: <LocationFilter label={t('location')} /> },
    {
      label: t('label_values'),
      filterName: FilterFieldName.labelValueIds,
      filter: <LabelValuesFilter filterFieldName={FilterFieldName.labelValueIds} />,
    },
  ];

  return (
    <TabbedPageLayout>
      <TabbedPageLayoutBody>
        <div className="flex h-full flex-grow flex-col  ">
          <div className="flex items-center justify-end mb-2">
            <LocationHierarchyToggleButton displayMode={displayMode} onChange={onBalancenDisplayChanged} reverseOrder />
          </div>
          <div className="mb-2 flex items-center">
            <FilterBar
              onSearch={handleSearch}
              onChange={onFilterChange}
              initialFilterValues={{}}
              showAsPopover
              isLoading={isLoading}
              filterComponents={filterComponents}
            ></FilterBar>
          </div>
          {displayMode === DisplayMode.HIERARCHICAL ? (
            <LocationsBalanceisHierarchicalGrid
              data={data}
              isError={isError}
              isLoading={isLoading}
              initialSortOption={hierarchicalSortOption}
              initialSortOrder={hierarchicalSortOrder}
              onSortChanged={onSortOptionChanged}
            />
          ) : (
            <LocationsBalanceGrid
              data={data}
              isError={isError}
              isLoading={isLoading}
              initialSortOption={individualSortOption}
              initialSortOrder={individualSortOrder}
              onSortChanged={onSortOptionChanged}
            />
          )}
        </div>
      </TabbedPageLayoutBody>
    </TabbedPageLayout>
  );
};

export default BalanceOverviewPage;
